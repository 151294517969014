import * as React from 'react';
import ContentLoader from 'react-content-loader';
import Moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as HandleRequest from '../../helper/Request';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as CancelAppointmentStore from '../../store/appointment/CancelAppointmentModel';
import * as AccountStore from '../../store/siteaccount/Account';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import { Field, reduxForm, reset } from 'redux-form';
import { renderField } from '../../helper/renderfield';
import { required } from '../../helper/formvalidation';
import { floatingJS } from '../../helper/Script';
import { toastr } from 'react-redux-toastr';
// At runtime, Redux will merge together...
type CancelAppointmentProps =
    LanguageResourcesStore.LanguageResouresState & CancelAppointmentStore.CancelAppointmentState & AccountStore.AccountState & TimeZoneStore.TimeZoneState & HandleRequest.Request  // ... state we've requested from the Redux store
    & typeof LanguageResourcesStore.actionCreators & typeof CancelAppointmentStore.actionCreators & typeof AccountStore.actionCreators & typeof TimeZoneStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var languagecontent: any = "";
var cancelduration: any = "";
var Currenttimezone: any = [];
class CancelAppointmentModel extends React.PureComponent<CancelAppointmentProps> {
    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        floatingJS();
        this.ensureDataFetched();
    }
    onSubmit = (formValues: any) => {
        if (formValues.reason.trim() != "") {
            // console.log(this.props.cancelAppointment)
            cancelduration = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "cancel duration prior to appointment")[0];
            if (cancelduration != undefined) {
                cancelduration = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "cancel duration prior to appointment")[0].customizeValue;
            }
            const patientLocalStroage: any = localStorage.getItem('Patient');
            const patient = JSON.parse(patientLocalStroage);
            if (this.props.TimeZones.length != 0) {
                Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
            }
            const tzOffset = Currenttimezone[0].Offset;
            const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
            const totalMinuteOffset = hourOffset * 60 + minuteOffset;
            var d = new Date();
            // d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            var currentdate = Moment(d).format("YYYY-MM-DDTHH:mm:ss");

            //console.log(currentdate)
            //var app_Date = this.props.cancelAppointment.appointmentDate == undefined ? this.props.cancelAppointment.appointmentOn : this.props.cancelAppointment.appointmentDate;
            var app_Date = this.props.cancelAppointment.appointmentUTCDate;

            var ad = new Date(app_Date);
            //ad.setHours(ad.getHours(), ad.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            var appDate = Moment(ad).format("YYYY-MM-DDTHH:mm:ss");
            //var appDate = this.props.cancelAppointment.appointmentDate == undefined ? this.props.cancelAppointment.appointmentOn : this.props.cancelAppointment.appointmentDate;

            let utcMoment = Moment().utc();

            let formattedUtcString = utcMoment.format("YYYY-MM-DDTHH:mm:ss");

            // Display the formatted UTC time

            var isvalue = checkCancelDatetime(appDate, cancelduration, formattedUtcString);




            //var isvalue = checkCancelDatetime(appDate, cancelduration, currentdate);

            if (isvalue == true)
                this.props.requestScheduleCancel(formValues)
            else
                toastr.error('', languagecontent['cancelappointment'] + ' ' + cancelduration + " " + "mins" + " " + languagecontent['priortoappoint'])
        } else {
            this.props.clearForm();
        }
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderCancelAppointment()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (patient.timeZone != "") {
            this.props.requestTimeZone(patient.timeZone);
        }
    }
    closeModel = () => {
        let element: HTMLElement = document.getElementsByClassName('close')[0] as HTMLElement;
        element.click();
        this.props.clearForm();
    }

    private renderCancelAppointment() {

        languagecontent = this.props.LanguageResoures;
        //console.log(this.props.cancelAppointment)

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                <div className="modal fade" id="dCancelModal" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{languagecontent['reasonforcancellation']}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModel()}>&times;</button>
                            </div>
                            <div className="modal-body">

                                <div className="row form-row">
                                    <div className="col-12">
                                        <div className="form-group form-focus">
                                            <Field name="reason" component={renderField} label={languagecontent['reasonforcancellation']} maxLength={100} validate={[required]} type="text" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.closeModel()}>{languagecontent['close']}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.props.isCancelLoading} >{this.props.isCancelLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['save']}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapDispatchToProps = { ...LanguageResourcesStore.actionCreators, ...CancelAppointmentStore.actionCreators, ...AccountStore.actionCreators, ...TimeZoneStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => {
    let values = { ...state.langResources, ...state.cancelAppointment, ...state.account, ...state.timeZones };
    return values;
}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'cancelappointmentform',
    enableReinitialize: true,
})(CancelAppointmentModel as any))




export const checkCancelDatetime = (datetime: any, duration: any, currentdate: any) => {
    if (duration == "0") {
        if (datetime >= currentdate) {
            return true
        } else {
            return false
        }
    } else if (duration == "-1") {
        return true
    } else {
        //var remaining: any = ((Moment.utc(datetime).local() - Moment().local()) / (1000 * 60 * 60)) * 60
        const appointmentDate: any = new Date(datetime);
        const currentDate: any = new Date(currentdate);
        const timeDifferenceInMilliseconds: any = appointmentDate.getTime() - currentDate.getTime();
        const timeDifferenceInMinutes: any = timeDifferenceInMilliseconds / (1000 * 60);
        if (timeDifferenceInMinutes >= duration) {
            return true
        } else {
            return false
        }
    }
    //if (duration == "") {
    //    return true;
    //}
    //else if (duration === "0") {
    //    var appointmentdate: any = add_minutes(new Date(datetime), duration);
    //    var currentdatetime: any = new Date(currentdate);
    //    if (appointmentdate == currentdatetime)
    //        return true;
    //    else
    //        return false;
    //}
    //else if (duration === "-1") {
    //    return true;
    //} else {
    //    var appointmentdate: any = add_minutes(new Date(datetime), duration);
    //    var currentdatetime: any = new Date(currentdate);
    //    if (appointmentdate < currentdatetime)
    //        return false;
    //    else
    //        return true;
    //}
}
var add_minutes = function (dt: any, minutes: any) {
    return new Date(dt.getTime() - minutes * 60000);
}
