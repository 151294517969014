import axios from 'axios';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { email } from '../../helper/formvalidation';
import { Stream } from 'stream';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { ReactFragment } from 'react';
import { dateUTCFormat, dateFormat } from '../../helper/formvalidation';
import { actionCreators as _paymentAction } from '../patient/Transaction';
import { actionCreators as _encounterModuleAction } from '../appointment/encounterModule';
//import Moment from 'moment';
//import { actionCreators as _flexiAction } from '../../library/flexiform/FlexiForm';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
import { actionCreators as _NavDefault } from '../../store/menu/NavDefault';

export interface VisitDetailState {
    visitDetailLoading: boolean;
    providerDetail: ProviderDetail;
    appointmentDetail: AppointmentDetail;
    encounterDetail: EncounterDetail;
    medication: MedicationDetail[];
    PatientVitals: PatientVital[];
    notes: Note[];
    PatientAllergy: [];
    Lab: [],
    Radiology: [],
    clientInstruction: ClientInstruction;
    CustomForms: CustomForm[];
    BMI: BMI;
}
export interface ClientInstruction {
    clientInstruction: string;
    modifiedUser: string;
    modifiedOn: string;
}
export interface CustomForm {
    modifiedOn: string;
    modifiedUser: string;
    clientTitle: string;
    title: string;
    value: string;
}
export interface BMI {
    bMI: string;
    bMIColor: string;
    bMIValue: string;
}
export interface ProviderDetail {
    accountName: string;
    doctorId: string;
    doctorName: string;
    gender: string;
    speciality: Speciality[];
    photo: string;

}
export interface Note {
    id: string;
    description: string;
    modified: Modified;
    modifiedOn: string;
}
export interface Modified {
    fullName: string;
}
export interface Speciality {
    id: string;
    name: string;
}

export interface AppointmentDetail {
    appointmentId: string;
    appointmentOn: string;
    appointmentType: string;
    reason: string;
    referenceNo: string;
    statusId: string;
    statusName: string;
    consultantName: string;
    hummyURL: string;
    cancelReason: string;
    rescheduleRefId: string;
}
export interface MedicationDetail {
    days: string;
    prescribedOn: string;
    prescriptionId: string;
    drug: Drug;
    prescribedBy: string;
    refill: any;
    quantity: string;
    sIG: string;
    status: string;
    note: string
    prescription: Prescription;
    user: User;
    pharmacyName: string;
    startedOn: string;
    direction: string;
    extRefId: string;
    purchaseUrl: string;
}
export interface EncounterDetail {
    id: string;
    patientId: string;
    encounterTypeId: string;
    providerId: string;
    appointmentId: string;
    encounterTypeName: string;
    encounterOn: string;
    statusName: any;
    statusId: number;
    reason: string;
    encounterNo: string;
    encounterTypeMode: string;
    note: string;
    oldProviderId: string;
}
export interface Drug{
    name: string;
}
export interface Prescription {
    statusId: number;
    id: string;
    extRefId:string;
}
export interface User {
    fullName: string;
    userId: string;
}
export interface Formula {
    Id?: any;
    ParentUnitCode: string;
    UnitCode: string;
    UnitLong: string;
    Expression1: string;
    Expression2: string;
}

export interface Vital {
    id: string;
    groupCode?: any;
    groupName?: any;
    code: string;
    name: string;
    formulaId: string;
    options: string;
    colorId: number;
    vitalColor: number;
    order?: any;
    statusId?: any;
    isSystem?: any;
    format?: any;
    controlId?: any;
    ControlTypeName?: any;
    dataTypeId?: any;
    dataType?: any;
    siteSettingOptionId: string;
    highExpression: string;
    value: string;
    operatorId?: number;
    customizeValue: string;
    bMI?: any;
    vitalMasterId: string;
    status?: any;
    formula: Formula;
    category?: any;
    unitType?: any;
    patientVitals?: any;
    vitalValidation?: any;
    vitalNameOptions?: any;
    modifiedBy?: any;
    modifiedOn?: any;
    modified?: any;
}

export interface PatientVital {
    id: string;
    patientId?: any;
    takenOn: Date;
    vitalId: string;
    colorId: number;
    value: string;
    encounterId: string;
    sessionID?: any;
    siteSettingOptionId?: any;
    patient?: any;
    vital: Vital;
    formula?: any;
    patientVitalDetail?: any;
    modifiedBy: string;
    modifiedOn: Date;
    modified?: any;
}

export interface patientAllergy {
    id: string;
    categoryId: any;
name: string;
allergyTypeName: string;
statusId: number;
reaction: string;
note: string;
accountId: any;
category: any;
status: any;
patientAllergies: any;
    modifiedBy: string;
    modifiedOn: Date;
    modified?: any;

   
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestVisitDetailAction { type: 'REQUEST_VISIT_DETAIL', providerDetail: ProviderDetail, appointmentDetail: AppointmentDetail, encounterDetail: EncounterDetail, medication: MedicationDetail[], PatientVitals: PatientVital[], notes: Note[], Lab: [], PatientAllergy: [], Radiology: [], clientInstruction: ClientInstruction, BMI: BMI, CustomForms: CustomForm[] }
export interface ReciveVisitDetailAction { type: 'RECEIVE_VISIT_DETAIL', providerDetail: ProviderDetail, appointmentDetail: AppointmentDetail, encounterDetail: EncounterDetail, medication: MedicationDetail[], PatientVitals: PatientVital[], notes: Note[], Lab: [], PatientAllergy: [], Radiology: [], clientInstruction: ClientInstruction, BMI: BMI, CustomForms: CustomForm[]}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestVisitDetailAction | ReciveVisitDetailAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestVisitDetail: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        var appointmentid = value.appointmentid;
        const appState: any = getState();
        function toCamel(o: any) {
            var newO: any, origKey: any, newKey: any, value: any
            if (o instanceof Array) {
                return o.map(function (value) {
                    if (typeof value === "object") {
                        value = toCamel(value)
                    }
                    return value
                })
            } else {
                newO = {}
                for (origKey in o) {
                    if (o.hasOwnProperty(origKey)) {
                        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                        value = o[origKey]
                        if (value instanceof Array || (value !== null && value.constructor === Object)) {
                            value = toCamel(value)
                        }
                        newO[newKey] = value
                    }
                }
            }
            return newO
        }
        var accountid: any = localStorage.getItem("AccountId");
        const urlParams = new URLSearchParams(window.location.search);
        const memberid = urlParams.get('memberid');
        var langcode: any = localStorage.getItem("DefaultLangCode");
        axios.get('/appointment/GetDetails/' + appointmentid + "/" + accountid + "/" + memberid + "/" + langcode)
            .then(function (response) {
                const result = toCamel(response.data);
                //console.log(response.data);
                var patientLocalStroage: any = localStorage.getItem('Patient');
                var patientdata = JSON.parse(patientLocalStroage);
                var _notes = result.notes.filter((n: any) => n.encounterId == result.encounterDetail.id);

                var _medication = result.medication.filter((me: any) => me.prescription.statusId != "6");

                if (result.appointmentDetail != undefined) {
                    if (result.appointmentDetail != null) {
                        if (result.encounterDetail.statusId == 7 && result.encounterDetail.appointmentId == null) {
                            result.encounterDetail.statusId = 8;
                            if (result.appointmentDetail != undefined) {
                                if (result.appointmentDetail != null) {
                                    result.appointmentDetail.statusId = 8;
                                }
                            }
                        }
                    }
                }

                //console.log(result.lab)
                const filterUnique = (arr:any, key:any) => {
                    const seen = new Set();
                    return arr.filter( (item: { [x: string]: any; })  => {
                        const val: any = item[key];
                        if (seen.has(val)) {
                            return false; // value is already seen
                        }
                        seen.add(val);
                        return true; // value is unique
                    });
                };
                const labData = filterUnique(result.lab, 'id');
                const RadiologyData = filterUnique(result.radiology, 'id');
               

                dispatch({ type: 'RECEIVE_VISIT_DETAIL', providerDetail: result.providerDetail, appointmentDetail: result.appointmentDetail == undefined ? unloadedState.appointmentDetail : result.appointmentDetail, encounterDetail: result.encounterDetail, medication: _medication, PatientVitals: result.vital.vitals, notes: _notes, PatientAllergy: result.patientAllergy, Lab: labData, Radiology: RadiologyData, clientInstruction: result.clientInstruction == undefined ? unloadedState.clientInstruction : result.clientInstruction , BMI: result.vital.bMI, CustomForms: result.customForms, visitDetailLoading: false });
                dispatch(_paymentAction.requestTransactionDetail(patientdata.id, result.encounterDetail.id));
                dispatch(_encounterModuleAction.getEncounterModuleList(patientdata.id, result.encounterDetail.id, "1"));
                //if (result.encounterDetail.patientId == patientdata.id) {
                //    dispatch({ type: 'RECEIVE_VISIT_DETAIL', providerDetail: result.providerDetail, appointmentDetail: result.appointmentDetail, encounterDetail: result.encounterDetail, medication: result.medication, PatientVitals: result.vital,  visitDetailLoading: false });
                //    dispatch(_paymentAction.requestTransactionDetail(patientdata.id, result.encounterDetail.id));
                //}
                //else {
                //    window.location.href = "/dashboard";
                //}
            })
            .catch(error => {
                if (error.response.data.statusCode == 400) {
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    toastr.error('', errorObject.error_description);
                } else {
                    if (error.response.data == "Access denied, contact support.") {
                        window.location.href = "/accessdenied";
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                }
            })
            .then(function () {
                // always executed
            });
        dispatch({ type: 'REQUEST_VISIT_DETAIL', visitDetailLoading: true });
    }, requestPrintDetail: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        var prescriptionid = value;

        var accountid: any = localStorage.getItem("AccountId");

        axios.get('/appointment/GetPrintDetails/' + prescriptionid + "/" + accountid)
            .then(function (response) {
                const result = response.data;
                
                var doc = new jsPDF("p", "mm", "a4");
              
                const primaryColor = getComputedStyle(document.documentElement)
                    .getPropertyValue('--primary-color');
                
                doc.setFontSize(22);
               
             
                
                //doc.text(35, 25, "Octonyan loves jsPDF");
                //doc.addImage(imgData, 'JPEG', 15, 40, 180, 180);
                if (result.SiteLogo !== null) {
                    doc.addImage(result.SiteLogo, 'JPEG', 10, 5, 50, 10);
                }
                //doc.addImage(result.SiteLogo, 'JPEG', 10, 5, 50, 10);
                doc.setFontSize(17)

                doc.text("Prescription", 100, 10, { align: "center" });
                doc.setFontSize(10)
                var xaxis = 10;
                var yaxis = 25;
                var yaxisR = 25;
                var lMargin = 30; //left margin in mm
                var rMargin = 15; //right margin in mm
                var pdfInMM = 85;  // width of A4 in mm
                doc.text("Patient Name", xaxis, yaxis);
                if (result.Patient.PatientName != null && result.Patient.PatientName != "") {
                    doc.text(result.Patient.PatientName, xaxis + 40, yaxis);
                }        

                doc.text("Prescribed By ", xaxis + 120, yaxis);
                if (result.Prescription.DoctorName != null && result.Prescription.DoctorName != "") {
                   // result.Prescription.DoctorName="fuif ihgf ig odg dsgjd fodgdfog gkd"
                    let lines = doc.splitTextToSize(result.Prescription.DoctorName, (pdfInMM - lMargin - rMargin));
                    //doc.text(30, 20, lines);
                    /*doc.splitTextToSize(result.Prescription.DoctorName, 7.5)*/
                    doc.text(lines, xaxis + 190, yaxisR, { align: "right" });
                    if (result.Prescription.DoctorName.trim().length > 25) {
                        yaxisR = pdfInMM - lMargin - rMargin
                        yaxisR = yaxisR -5
                    }
                    else {
                        yaxisR = yaxisR+5
                    }
                }
                yaxis = 30
               
                doc.text("Patient No", xaxis, yaxis);
                if (result.Patient.PatientNo != null && result.Patient.PatientNo != "") {
                    doc.text(result.Patient.PatientNo, xaxis + 40, yaxis);
                }
               
                
                doc.text("Prescribed On ", xaxis + 120, yaxisR);
                if (result.Prescription.PrescribedOn != null && result.Prescription.PrescribedOn != "") {
                    doc.text(dateFormat(result.Prescription.PrescribedOn) + " " + Moment(result.Prescription.PrescribedOn).format('LT'), xaxis + 190, yaxisR, { align: "right" });
             }
                yaxis = 35
                yaxisR = yaxisR + 5
                doc.text("Age", xaxis, yaxis);
                if (result.Patient.PatientAge != null && result.Patient.PatientAge != "") {
                    doc.text(result.Patient.PatientAge, xaxis + 40, yaxis);
                }

                doc.text("Prescription No ", xaxis + 120, yaxisR);
                if (result.Prescription.RxNo != null && result.Prescription.RxNo != "") {
                    doc.text(result.Prescription.RxNo, xaxis + 190, yaxisR, { align: "right" });
                }

                yaxis = 40
                yaxisR = yaxisR+5
                doc.text("Gender", xaxis, yaxis);
                if (result.Patient.PatientGender != null && result.Patient.PatientGender != "") {
                    doc.text(result.Patient.PatientGender, xaxis + 40, yaxis);
                }

                
                if (result.Facility.Address1 != null && result.Facility.Address1 != "") {
                    doc.text("Provider Address ", xaxis + 120, yaxisR);
                    let lines = doc.splitTextToSize(result.Facility.Address1, (pdfInMM - lMargin - rMargin));
                    doc.text(lines, xaxis + 190, yaxisR, { align: "right" });
                   // doc.text(result.Facility.Address1, xaxis + 190, yaxisR, { align: "right" });
                   // doc.text("dsjfsdh sdf", xaxis + 190, yaxis, { align: "right" });
                    if (result.Facility.Address1.trim().length <=21) {
                        yaxisR = yaxisR + 10
                        
                    }
                    else if (result.Facility.Address1.trim().length > 21 && result.Facility.Address1.trim().length<45){
                        yaxisR = yaxisR + 15
                        /*yaxisR = yaxisR + 3*/
                    }
                    else if (result.Facility.Address1.trim().length > 45 && result.Facility.Address1.trim().length < 65)
                    {
                        yaxisR = yaxisR + 15
                        yaxisR = yaxisR + 3
                        
                    }
                    else if (result.Facility.Address1.trim().length >  65) {
                        yaxisR = yaxisR + 15
                        yaxisR = yaxisR + 3
                        yaxisR = yaxisR + 3
                    }
                    else {
                        yaxisR = yaxisR + 5
                    }
                   
                }

                yaxis = 45
                
                if (result.Patient.Address1 != null && result.Patient.Address1 != "") {
                    doc.text("Patient Address", xaxis, yaxis);
                    doc.text(result.Patient.Address1, xaxis + 40, yaxis);
                    //doc.text("sfjk", xaxis + 40, yaxis);
                    yaxis = 50
                }
              /*  if (result.Facility.Address2 != null && result.Facility.Address2 != "") {
                    doc.text(result.Facility.Address2, xaxis + 190, yaxisR, { align: "right" });
                    yaxisR = 50
                }


                if (result.Patient.Address2 != null && result.Patient.Address2 != "") {
                    
                    doc.text(result.Patient.Address2, xaxis + 40, yaxis);
                    yaxis = 55
                }
                if (result.Facility.City != null && result.Facility.City != "") {
                    doc.text(result.Facility.City, xaxis + 190, yaxis, { align: "right" });
                    yaxisR=55
                }
                if (result.Patient.City != null && result.Patient.City != "") {
                    
                    doc.text(result.Patient.City, xaxis + 40, yaxis);
                    yaxis = 60
                }*/
                yaxisR = yaxisR - 5;
                if (result.Facility.CellPhone != null && result.Facility.CellPhone != "") {
                    doc.text("Phone No ", xaxis + 120, yaxisR);
                    doc.text(result.Facility.CellPhone, xaxis + 190, yaxisR, { align: "right" });
                    //doc.text("0987654321", xaxis + 190, yaxisR, { align: "right" });
                    yaxisR = yaxisR+5
                }
                if (result.Patient.CellPhone != null && result.Patient.CellPhone != "") {
                    doc.text("Phone No", xaxis, yaxis);
                    doc.text(result.Patient.CellPhone, xaxis + 40, yaxis);
                    yaxis=55;
                }
                if (result.Facility.Email != null && result.Facility.Email != "") {
                    doc.text("Email ", xaxis + 120, yaxisR);
                    let lines = doc.splitTextToSize(result.Facility.Email, (pdfInMM - lMargin - rMargin));
                    doc.text(lines, xaxis + 190, yaxisR, { align: "right" });
                    if (result.Facility.Email.trim().length > 20) {
                        yaxisR = pdfInMM - lMargin - rMargin
                        yaxisR = yaxisR +10 
                    }
                    else {
                        yaxisR = yaxisR + 5
                    }
                    //doc.text(result.Facility.Email, xaxis + 190, yaxisR, { align: "right" });
                   // doc.text("0987654321", xaxis + 190, yaxisR, { align: "right" });
                }
                if (result.Patient.Email != null && result.Patient.Email != "") {
                    doc.text("Email ", xaxis, yaxis );
                    doc.text(result.Patient.Email, xaxis + 40, yaxis);
                   // doc.text("ttttt@gmail.com", xaxis + 40, yaxis + 5);
                }

                /*doc.text("Provider Address ", xaxis + 130, yaxis);
                doc.text(dateFormat(result.Prescription.PrescribedOn) + " " + Moment(result.Prescription.PrescribedOn).format('LT'), xaxis + 200, yaxis, { align: "right" });
                */
                var header: any = ['S.No.', 'Drug Name', 'Days', 'Qty', 'Route of Adminstration'];
                var drugTableData: any = [];
                var footerData: any = []
                var colSpan:any = header.length;

                result.DrugDetails.forEach((drug: any, index: number) => {
                    drugTableData.push([index + 1, drug.DrugName + "\n(" + drug.SIG + ")", drug.Days, drug.Quantity, drug.RouteOfAdministration != null && drug.RouteOfAdministration != "" ? drug.RouteOfAdministration : "-"])
                    
                    
                })

                if (drugTableData.length == 0 && result.Prescription.Note !== "") {
                    header = ["Note", '','','','','']
                    footerData = [`${result.Prescription.Note}`]
                    colSpan = 5
                   
                    console.log('in')
                } else if (drugTableData.length == 0 && result.Prescription.Note == "") {
                    header = []
                    footerData = [[]]
                } else if (drugTableData.length > 0 && result.Prescription.Note == "") {
                    footerData = []                 
                } else if (drugTableData.length > 0 && result.Prescription.Note != "") {                
                    footerData = [`Note: ${result.Prescription.Note}`]                  
                }


                //console.log(footerData, colSpan)

                const hasFooter: any = footerData.length > 0;
                colSpan = header.length

                    //autoTable(doc, {
                    //    head: [header],
                    //    body: drugTableData,                      
                    //    startY: 70,
                    //    tableWidth: "auto",
                    //    styles: {
                    //        fontSize: 12,
                    //    },                      
                    //    headStyles: { fillColor: primaryColor },
                    //    alternateRowStyles: { fillColor: [248, 248, 248] },
                    //    tableLineWidth: 0.1
                    //});
             
                autoTable(doc,{
                    head: [header],
                    body: drugTableData,
                    startY: 70,
                    tableWidth: 'auto',
                    styles: {
                        fontSize: 12,
                    },
                    headStyles: { fillColor: primaryColor },
                    alternateRowStyles: { fillColor: [248, 248, 248] },                   
                    foot: hasFooter ? [[{ content: footerData.join("\n"), colSpan: colSpan }]] : [],
                    footStyles: hasFooter ? { fillColor: [248, 248, 248], fontStyle: 'normal', textColor: [90, 90, 90] } : undefined,
                    tableLineWidth: 0.1,
                });
                   
                
                doc.setFontSize(12)
                let finalY = (doc as any).lastAutoTable.finalY;
                yaxis = 130
                doc.text("Note: This prescription is generated on a teleconsultation", xaxis, finalY+20);              
                doc.autoPrint();
                doc.output('dataurlnewwindow');
              })
            .catch(error => {
                if (error.response && error.response.data) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                } else {
                    console.log(error)
                }
            })
            .then(function () {
                // always executed
            });
        //dispatch({ type: 'REQUEST_VISIT_DETAIL', visitDetailLoading: true });
    },
    redirectdrProfile: (providerdetail: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        if (providerdetail.doctorName == "Not Assigned") {
            dispatch(_NavDefault.goNextPage());
        } else {
            dispatch(push("/booking/" + providerdetail.doctorId));
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: VisitDetailState = {
    appointmentDetail: {
        appointmentId: "",
        appointmentOn: "",
        appointmentType: "",
        reason: "",
        referenceNo: "",
        statusId: "",
        statusName: "",
        consultantName: "",
        hummyURL: "",
        cancelReason: "",
        rescheduleRefId : ""
    },
    providerDetail: {
        accountName: "",
        doctorId: "",
        doctorName: "",
        speciality: [],
        gender: "",
        photo:""

    },
    encounterDetail: {
        id: "",
        patientId: "",
        encounterTypeId: "",
        providerId: "",
        appointmentId: "",
        encounterTypeName: "",
        encounterOn: "",
        statusName: "",
        statusId: 1,
        reason:"",
        encounterNo:"",
        encounterTypeMode: "",
        note:"",
        oldProviderId: "",
    },
    clientInstruction: {
        clientInstruction: "",
        modifiedUser: "",
        modifiedOn: ""
    },
    notes: [],
    visitDetailLoading: true,
    medication: [],
    PatientVitals: [],
    PatientAllergy: [],
    Lab: [],
    Radiology: [],
    BMI: {
        bMI: "",
        bMIColor: "",
        bMIValue: ""
    },
    CustomForms:[]
};
export const reducer: Reducer<VisitDetailState> = (state: VisitDetailState | undefined, incomingAction: Action): VisitDetailState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_VISIT_DETAIL':
            return {
                appointmentDetail: state.appointmentDetail,
                providerDetail: state.providerDetail,
                encounterDetail: state.encounterDetail,
                visitDetailLoading: true,
                medication: state.medication,
                PatientVitals: state.PatientVitals,
                notes: state.notes,
                PatientAllergy: state.PatientAllergy,
                Lab: state.Lab,
                Radiology: state.Radiology,
                clientInstruction: state.clientInstruction,
                BMI: state.BMI,
                CustomForms: state.CustomForms
            };
        case 'RECEIVE_VISIT_DETAIL':
            return {
                appointmentDetail: action.appointmentDetail,
                providerDetail: action.providerDetail,
                encounterDetail: action.encounterDetail,
                visitDetailLoading: false,
                medication: action.medication,
                PatientVitals: action.PatientVitals,
                notes: action.notes,
                PatientAllergy: action.PatientAllergy,
                Lab: action.Lab,
                Radiology: action.Radiology,
                clientInstruction: action.clientInstruction,
                BMI: action.BMI,
                CustomForms: action.CustomForms
            };

        default:
            return state;
    }
};

