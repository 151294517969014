import axios from 'axios';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { errorHandling } from '../../../helper/ErrorHandling';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { actionCreators as _paymentProcess } from '../../payment/PaymentProcess';
import moment from 'moment';
import { datepickerJS, floatingJS, floatJS, seo, floatJSBack, ltrim } from '../../../helper/Script';
import { convertYeartoDate, dateFormat, dateFormatSetting, datePostFormat, scrollTop, toConvertDate } from '../../../helper/formvalidation';
import { reset } from 'redux-form';



// -----------------
// STATE - This defines the type of data maintained in the Redux store.
const search = window.location.search;
const params = new URLSearchParams(search);
const urlParams = new URLSearchParams(window.location.search);
const childid = urlParams.get('childid');
const name = urlParams.get('childname');
const childname = urlParams.get('childname');
export interface FlexiListsState {
    isdocformLoading: boolean;
    isskipLoading: boolean;
    isformLoading: boolean;
    formLists: FormList[];
    isFormListLoading: boolean;
    dynamicForm: any;
    moduleLists: ModuleList[];
    ResultHtml: string,
    CustomFormSetting: CustomFormSetting[];
}

export interface FormList {
    id: string;
    moduleName: string;
    moduleOrder: number;
    moduleParentId: number;
    moduleId: number;
    customFormId: string;
    customFormName: string;
    description: string;
    isAllowMultiple: boolean;
    parentId: string;
    customFormRule: string;
    title: string;
    subTitle: string;
    isRequired: boolean;
    rule: string;
    styleId: number;
    name: string;
    clientTitle: string;
    clientSubTitle: string;
    formClientTitle: string;
    providerTitle: string;
    providerSubTitle: string;
    option1: string;
    option2: string;
    defaultValue: string;
    errorMessage: string;
    controlId: number;
    dataTypeId: number;
    order: number;
    dataMask: string;
    minLength: string;
    maxLength: string;
    controls: string;
    dataType: string;
    style: string;
    formType: string;
    value: string;
    customFormInputDetailId: string;
    customFormParentId: string;
    customFormDisplayOrder: number;
    customFormFieldsId: string;
    template: string;
    
}

export interface ModuleList {
    id: string;
    moduleId: number;
    moduleName: string;
    formClientTitle: string;
    modifiedBy: string;
    modifiedOn: string;
    showOnce: boolean;
    isRead: boolean;
    isWrite: boolean;
    isDelete: boolean;
}
export interface CustomFormSetting {
    moduleId: string
    moduleName: string
    moduleOrder: string
    moduleParentId: string
    name: string
    operator: string
    value: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDocumentListsAction {
    type: 'REQUEST_FORM_LIST';
}

interface ReceiveDocumentListsAction {
    type: 'RECEIVE_FORM_LIST';
    formLists: FormList[],
    ResultHtml: string,
    CustomFormSetting: any,
}
interface ReceiveDynamicFormInitAction {
    type: 'RECEIVE_DYNAMICFORM_VALUES';
    dynamicForm: any;

}
interface RequestModuleListsAction {
    type: 'REQUEST_MODULE_LIST';
}

interface ReceiveModuleListsAction {
    type: 'RECEIVE_MODULE_LIST';
    moduleLists: ModuleList[];
}

interface RequestskipAction {
    type: 'REQUEST_SKIP';

}
interface RequestNoAction {
    type: 'REQUEST_NO_ACTION';
    ResultHtml: string,
    CustomFormSetting: CustomFormSetting[]

}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDocumentListsAction | ReceiveDocumentListsAction | ReceiveDynamicFormInitAction | ReceiveModuleListsAction | RequestModuleListsAction | RequestskipAction | RequestNoAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestFlexiForm: (patientId: any, moduleName: any, encounterId: any, id: any, recordid: any, encounterTypeId: any, areaId: any): AppThunkAction<KnownAction> => async (dispatch: any, getState: any) => {
        var pathname: any = window.location.pathname.split('/');
        dispatch({ type: 'REQUEST_FORM_LIST' });
        const appState = getState();
        var current_id = null;
        const urlParams = new URLSearchParams(window.location.search);
        current_id = urlParams.get('currentid');
        //console.log(current_id)

        //var module_Name: any = moduleName
        //module_Name = module_Name.replace(/[%\s0-9]/g, "").toLowerCase();
        //if (pathname[1] == "questionnaire") {
        //    if (appState.flexiform.moduleLists && appState.flexiform.moduleLists.length > 0) {
        //        //console.log(appState.flexiform.moduleLists, module_Name)
        //        const module_List: any = appState.flexiform.moduleLists.filter((list: any) => (list.moduleName.toLowerCase().replace(/[%\s]/g, "") === module_Name)) || []
        //        if (module_List.length > 0) {
        //            second_id = module_List[0].id;               
        //        } else if (localStorage.getItem('customId') !== null) {
        //            second_id = localStorage.getItem('customId')
        //        }
        //    }
        //}

        if (encounterId != "") {
            encounterId = encounterId;
        }
        else {
            encounterId = null;
        }
        if (id != "") {
            id = id;
        }
        else {
            id = null;
        }
        var modelareaid = areaId;
        
        
        function toCamel(o: any) {
            var newO: any, origKey: any, newKey: any, value: any
            if (o instanceof Array) {
                return o.map(function (value) {
                    if (typeof value === "object") {
                        value = toCamel(value)
                    }
                    return value
                })
            } else {
                newO = {}
                for (origKey in o) {
                    if (o.hasOwnProperty(origKey)) {
                        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                        value = o[origKey]
                        if (value instanceof Array || (value !== null && value.constructor === Object)) {
                            value = toCamel(value)
                        }
                        newO[newKey] = value
                    }
                }
            }
            return newO
        }
        if (moduleName == "")
            moduleName = params.get('modulename');
        if (patientId == "")
            patientId = params.get('patientid');
        if (recordid == "")
            recordid = params.get('recordid');


        //console.log(id)
        if (pathname[1].toLowerCase() == "questionnaire") {
            if ((id === null || id === 'null') && current_id !== null) {
                id = current_id;

            }
        }

       

        
        if (appState && appState.doctorLists) {
                        
            if ((pathname[1].toLowerCase() == "questionnaire") || (pathname[1].toLowerCase() == "forms") || (pathname[1].toLowerCase() == "initialpageforms")) {
                var isanswered:any = null;
                if (moduleName == "Recommendation%20(Wellness%20care%20plan)")
                   isanswered = 1;

                axios.get('/FlexiForm/GetFormList/' + patientId + "/" + null + "/" + moduleName + "/" + encounterId + "/" + id + "/" + recordid + "/" + modelareaid + "/" + isanswered)
                    .then(function (response) {
                        const result = toCamel(response.data.CustomView);
                        let res: any = result.map((val: any) => val.option1)
                        const resultCustomFormSetting = toCamel(response.data.CustomFormSetting);
                        const resultResultHtml = response.data.ResultHtml;

                        let dynamicFormData: any = [];
                        if (result) {
                            result.forEach((key: any, index: any) => {
                                if (key['value'] != "") {
                                    if (key['controls'] == "Date") {
                                        //dynamicFormData[key['customFormFieldsId']] = moment(new Date(key['value'])).format("DD MMM YYYY");
                                    //    var __dateformat: any = convertYeartoDate(key['value']);
                                    //    if (__dateformat == "Invalid date") {
                                    //        dynamicFormData[key['customFormFieldsId']] = "";
                                    //    } else {
                                    //        dynamicFormData[key['customFormFieldsId']] = datePostFormat(key['value']);
                                    //    }
                                    //}
                                        var inputDate: any = (key['value']);
                                        var parts: any = inputDate.split('-');
                                        var outputDate: any = parts[0] + '/' + parts[1] + '/' + parts[2];
                                        dynamicFormData[key['customFormFieldsId']] = outputDate;

                                        //console.log(outputDate);
                                        var ___dateformat: any = datePostFormat(key['value']);
                                        var __dateformat: any = convertYeartoDate(outputDate);
                                        if (__dateformat == "Invalid date") {
                                            dynamicFormData[key['customFormFieldsId']] = "";
                                        } else {
                                            dynamicFormData[key['customFormFieldsId']] = toConvertDate(key['value']);
                                        }
                                    }

                                  
                                    else {
                                        dynamicFormData[key['customFormFieldsId']] = ltrim(key['value']);
                                    }

                                }
                                else {
                                    dynamicFormData[key['customFormFieldsId']] = ltrim(key['defaultValue']);
                                }
                            })
                            dispatch({ type: 'RECEIVE_DYNAMICFORM_VALUES', dynamicForm: dynamicFormData });
                        }
                        dispatch({ type: 'RECEIVE_FORM_LIST', formLists: result, CustomFormSetting: resultCustomFormSetting, ResultHtml: resultResultHtml, isdocformLoading: false });
                        setTimeout(() => {
                            result.forEach((key: any, index: any) => {
                                if (key['value'] != "") {
                                    if (key['controls'] == "Textarea") {
                                        var rule = key['rule'];
                                        var Id = key['parentId'];
                                        var name = key['name'];

                                        var element: any = document.getElementsByClassName(Id + "-hide");

                                        if (name == "emptytextarea" && element.length != "0" && rule != "") {
                                            Array.from(element).forEach((e2: any) => {
                                                e2.style.display = "block";
                                            })
                                        }


                                    }
                                }
                            });

                        }, 1000)
                        //const childid = urlParams.get('childid');
                        //const childname = urlParams.get('childname');
                        //if (result.length != 0)
                        //dispatch(push("/" + pathname[1] + "/"+ pathname[2] + "/" + pathname[3] + "/" + pathname[4] + "/" + pathname[5] + "/" + pathname[6] + "/" + result[0].id + "?methodid=" + 2 + "&childid=" + childid + "&childname=" + childname));

                    })
                    .catch(error => {
                        //if (error.response.data.statusCode == 400) {
                        //    var errorObject = JSON.parse(error.response.data.errorMessage);
                        //    toastr.error('', errorObject.error_description);
                        //} else {
                        //    errorHandling(error.response.data.statusCode);
                        //}
                    })
                    .then(function () {
                    });
                dispatch({ type: 'REQUEST_FORM_LIST', isdocformLoading: true });
            }
        }
    },
    requestFormDisplay: (modulename: any, patientid: any, recordid: any, id: any, formclienttitle: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        window.location.href = `/forms/${modulename}/${patientid}/${recordid}/${id}?formclientTitle=${formclienttitle}`
//dispatch(push("/forms/" + modulename + "/" + patientid + "/" + recordid + "/" + id + "?formclientTitle=" + formclienttitle))
        //window.location.href = "/forms/" + modulename + "/" + patientid + "/" + recordid + "/" + id + "?formclientTitle=" + formclienttitle;
        //dispatch(push("/forms?modulename=" + modulename + "&patientid=" + patientid + "&recordid=" + recordid))
    },

    requestSaveQuestions: (formvalue: any, QuestionsInfo: any, patient: any, recordid: any, recordTypeId: any, module: any, moduleId: any, status: any, nextModule: any, moduleAreaId: any, nextId: any, list: any, recordsid:any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
       
        dispatch({ type: 'REQUEST_FORM_LIST', isformLoading: false });
        const appState: any = getState();
        let CustomFormId = QuestionsInfo.customFormId;
        let PatientId = patient.id;
        let EncounterId = null;
        let Id = QuestionsInfo.id;
        let RecordId = recordid;
        let ModuleName = module;
        //let ModuleName = "Prakriti Analysis";
        let EncounterTypeId = null;
        let ModifiedBy = null;
        let ModifiedOn = null;
        let StatusId = 1;
        let Note = "";
        let RecordTypeId = recordTypeId;
        var areaid = moduleAreaId;
        let data: any = [];
        let CustomFormInputDetails: any = [];
        var date = localStorage.getItem("AppointmentOn");
        var time = localStorage.getItem("AppointmentTime");
        var accountid = localStorage.getItem("AccountId");
        var encDetailStroage: any = localStorage.getItem('EncDetail');
        var encDetail = JSON.parse(encDetailStroage);
        var _patientReferrralId = localStorage.getItem("patientReferrralId");
        var doctorid = localStorage.getItem("doctorid");
        var patientLocalStroage: any = localStorage.getItem('Patient');
        var patientstore = JSON.parse(patientLocalStroage);
        var AppointmentId = localStorage.getItem("AppointmentId");
        var paidAmount = localStorage.getItem("paidAmount");
        var pathname: any = window.location.pathname.split('/');



        const multipleNotes: any = list.filter((value: any) => (value.controls.toLowerCase() == "multientrytextarea")) || [];

        
        

        function toCamel(o: any) {
            var newO: any, origKey: any, newKey: any, value: any
            if (o instanceof Array) {
                return o.map(function (value) {
                    if (typeof value === "object") {
                        value = toCamel(value)
                    }
                    return value
                })
            } else {
                newO = {}
                for (origKey in o) {
                    if (o.hasOwnProperty(origKey)) {
                        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                        value = o[origKey]
                        if (value instanceof Array || (value !== null && value.constructor === Object)) {
                            value = toCamel(value)
                        }
                        newO[newKey] = value
                    }
                }
            }
            return newO
        }
       
        Object.keys(formvalue).forEach((key: any, index: any) => {
            let object:any = {
                CustomFormFieldsId: key, Value: formvalue[key]
            }

            if (multipleNotes.length !== 0) {                
                if (key === multipleNotes[0].customFormFieldsId && formvalue[key] !== '' ) {
                    object.Ismultientry = true
                }
            }
            CustomFormInputDetails[index] = object
        });

        //moduleId = "41";
        data = {
            CustomFormId: CustomFormId,
            PatientId: PatientId,
            EncounterId: EncounterId,
            Id: Id,
            CustomFormInputDetails: CustomFormInputDetails,
            Note: Note,
            StatusId: StatusId,
            ModifiedBy: ModifiedBy,
            ModifiedOn: ModifiedOn,
            EncounterTypeId: EncounterTypeId,
            ModuleName: list.length > 0 ? list[0].moduleName : ModuleName,
            RecordId: RecordId,
            RecordTypeId: RecordTypeId,
            areaid: areaid,
            ModuleId: list.length > 0 ? list[0].moduleId : moduleId
        }
        var langdata: any;
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        dispatch(showLoading('sectionBar'));
        if (appState) {

            axios.post('/FlexiForm/Formsave/', data)
                .then(response => {
                    scrollTop();
                    const result = response.data;
                    dispatch(reset("FlexiForm"));
                    if (pathname[1].toLowerCase() == "questionnaire") {
                        const url_id: any = new URL(window.location.href);
                        url_id.searchParams.set('currentid', response.data.CustomList[0].Id);
                        window.history.replaceState({}, '', url_id);
                    }
                    
                    

                   //localStorage.setItem('customId', response.data.CustomList[0].Id)
                    //localStorage.removeItem("AppointmentOn");
                    //localStorage.removeItem("AppointmentTime");
                    //localStorage.removeItem("EncounterTypeId");
                    //localStorage.removeItem("pathName");
                    //localStorage.removeItem("redirectUrl");
                    ///*localStorage.removeItem("EncDetail");*/
                    //localStorage.removeItem("patientReferrralId");
                    dispatch(hideLoading('sectionBar'));
                    if (moduleAreaId == "4") {
                        var langdata: any;
                        if (localStorage.getItem("LangResourcesValidations") != null)
                            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                        if (recordsid == null || recordsid == "null" || recordsid == "" ) {
                            toastr.success('', langdata['successfuly']);

                        } else {
                            toastr.success('', langdata['updatedsuccessfully']);
                        }
                        setTimeout(() => {
                            dispatch(actionCreators.requestFlexiForm(PatientId, ModuleName, "", response.data.CustomList[0].Id, recordid, "", moduleAreaId))
                            window.location.href = `/forms/${ModuleName}/${PatientId}/${recordid}/${response.data.CustomList[0].Id}?formclientTitle=${ModuleName}`

                        },1000)
                        //   dispatch(push("/forms/" + ModuleName + "/" + PatientId + "/" + recordid + "/" + response.data.CustomList[0].Id + "/" + "?formclientTitle=" + ModuleName))
                        //window.location.reload();

                    }
                    else {
                  
                        var doctorid: any = localStorage.getItem("DoctorId");
                        if ((nextModule == "") && (moduleAreaId == "6")) {
                            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
                            var enablefamilymember: any = appState.account.Settings.filter((se: any) => se.name == "Enable Family Member")[0];
                            var doctorappointment: any = appState.account.Settings.filter((se: any) => se.name == "Doctor Appointment")[0]; // book now
                            var requestqueue: any = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
                            var anonymous: any = appState.account.Settings.filter((se: any) => se.name == "Anonymous")[0]; //pick a
                            var url = localStorage.getItem("redirectUrl");
                            if (url == null)
                                url = "";
                            else
                                url = localStorage.getItem("redirectUrl");
                            if (localStorage.getItem("pathName") == "conditions") {
                                if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {

                                    if (localStorage.getItem("Email") != patient.Email) {
                                        var urlcontact: any = localStorage.getItem("contactUrl");
                                        window.location.href = urlcontact;
                                    } else if (localStorage.getItem("pathName") == "conditions") {
                                        if (url != null)
                                            window.location.href = url;
                                    }
                                    else {
                                        if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                                            window.location.href = "/dashboard";
                                        }                         
                                       else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                                            window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                        }
                                        else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                                            var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                            if (queueurl == null) {
                                                window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                            } else {
                                                if (localStorage.getItem("pathName") == "RequestQueue") {
                                                    if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                                                        window.location.href = "/contact/" + null + "/" + patient.memberId;
                                                    } else {
                                                        window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                                    }
                                                }
                                            }
                                        }
                                        else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                                            var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                            if (queueurl == null) {
                                                window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                            } else {
                                                if (localStorage.getItem("pathName") == "RequestQueue") {
                                                    if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                                                        window.location.href = "/contact/" + null + "/" + patient.memberId;
                                                    } else {
                                                        window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                                    }
                                                }
                                            }
                                        }
                                        else if ((enablefamilymember.customizeValue == "Yes") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                                            var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                            if (queueurl == null) {
                                                window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                            } else {
                                                if (localStorage.getItem("pathName") == "RequestQueue") {
                                                    if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                                                        window.location.href = "/contact/" + null + "/" + result.memberId;
                                                    } else {
                                                        var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment");
                                                        window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                                    }
                                                }
                                            }
                                        } else if (enablefamilymember.customizeValue == "Yes") {
                                            window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                        }

                                        else {
                                            
                                            window.location.href = "/dashboard";
                                        }
                                    }
                                } else {
                                    if (url != null)
                                        window.location.href = url;
                                }
                            } else if (localStorage.getItem("pathName") == "RequestQueue") {
                                if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                                    window.location.href = "/dashboard";
                                }
                                else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                                    window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                }
                                else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                                    var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                    if (queueurl == null) {
                                        window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                    } else {
                                        if (localStorage.getItem("pathName") == "RequestQueue") {
                                            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                                                window.location.href = "/contact/" + null + "/" + patient.memberId;
                                            } else {
                                                var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                                window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                            }
                                        }
                                    }
                                }
                                else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                                    var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                    if (queueurl == null) {
                                        window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                    } else {
                                        if (localStorage.getItem("pathName") == "RequestQueue") {
                                            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                                                window.location.href = "/contact/" + null + "/" + patient.memberId;
                                            } else {
                                                window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                            }
                                        }
                                    }
                                }
                                else if ((enablefamilymember.customizeValue == "Yes") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                                    var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                    if (queueurl == null) {
                                        window.location.href = "/consultationrequest?memberid=" + result.memberId;
                                    } else {
                                        if (localStorage.getItem("pathName") == "RequestQueue") {
                                            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                                                window.location.href = "/contact/" + null + "/" + patient.memberId;
                                            } else {
                                                var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                                window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                            }
                                        }
                                    }
                                }
                                else if (enablefamilymember.customizeValue == "Yes") {
                                    window.location.href = "/consultationrequest?memberid=" + patient.memberId;
                                }
                                else {
                                    window.location.href = "/dashboard";
                                }
                            } 
                            else {
                                if ((enablefamilymember.customizeValue == "Yes") && ((doctorappointment.customizeValue == "Yes") || (requestqueue.customizeValue == "Yes") || (anonymous.customizeValue == "Yes"))) {
                                    window.location.href = "/consultationrequest?memberid=" + patient.memberId;

                                } else if (enablefamilymember.customizeValue == "No" &&
                                    ((doctorappointment.customizeValue == "No" && requestqueue.customizeValue == "Yes" && anonymous.customizeValue == "Yes") ||
                                        (doctorappointment.customizeValue == "Yes" && requestqueue.customizeValue == "No" && anonymous.customizeValue == "Yes") ||
                                        (doctorappointment.customizeValue == "Yes" && requestqueue.customizeValue == "Yes" && anonymous.customizeValue == "No") ||
                                        (doctorappointment.customizeValue == "Yes" && requestqueue.customizeValue == "Yes" && anonymous.customizeValue == "Yes"))) {
                                    window.location.href = "/consultationrequest?memberid=" + patient.memberId;

                                }
                                else if (enablefamilymember.customizeValue == "No" &&(
                                        (doctorappointment.customizeValue == "Yes" && requestqueue.customizeValue == "No" && anonymous.customizeValue == "No") ||
                                        (doctorappointment.customizeValue == "No" && requestqueue.customizeValue == "Yes" && anonymous.customizeValue == "No") ||
                                        (doctorappointment.customizeValue == "No" && requestqueue.customizeValue == "No" && anonymous.customizeValue == "Yes"))) {
                                    window.location.href = "/dashboard";

                                }
                                else {
                                    window.location.href = "/dashboard";
                                }
                            }
                        }
                        else if (nextModule == "") {
                            var patientstore: any = JSON.parse(patientLocalStroage);
                            const urlParams = new URLSearchParams(window.location.search);
                            let methodid = urlParams.get('methodid');
                            methodid = formvalue.MethodId ? formvalue.MethodId : methodid

                            if (status == "Yes") {
                                if ((paidAmount == "0") || (paidAmount == "0.00") || (paidAmount == null)) {
                                    var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                    if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                        dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + AppointmentId + "&doctorid=" + doctorid + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
                                    } else {
                                        dispatch(_paymentProcess.requestScheduleUpdateFree(AppointmentId))
                                    }
                                }
                                else {
                                    var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                    if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                        dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + AppointmentId + "&doctorid=" + doctorid + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
                                    } else {
                                        var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                                        var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                                        var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];
                                        if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid  + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/insurancelist?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid  + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                            //dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.FirstName + " " + patient.lastName + "&email=" + patient.email))
                                        } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                            dispatch(push("/paymentprocess/" + doctorid + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                                        } else {
                                            dispatch(push("/paymentprocess/" + doctorid + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                                        }
                                    }
                                }
                            }
                            else {                              
                                toastr.success('', langdata['updatedsuccessfully']);
                            }
                        }
                        else {
                            if (moduleAreaId == "6") {
                                //var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                                //toastr.success('', langdata['successfuly']);
                                dispatch(actionCreators.requestFlexiForm(PatientId, nextModule, "", null, recordid, "", moduleAreaId))
                                dispatch(push("/initialpageforms/" + nextModule + "/" + PatientId + "/" + recordid + "/" + null))
                            } else {
                                var patientstore: any = JSON.parse(patientLocalStroage);
                                dispatch(actionCreators.requestFlexiForm(PatientId, nextModule, "", nextId, recordid, localStorage.getItem("EncounterTypeId"), moduleAreaId))
                                const urlParams = new URLSearchParams(window.location.search);
                                const methodid = urlParams.get('methodid');
                                const childid = urlParams.get('childid');
                                const name = urlParams.get('childname');
                                const childname = urlParams.get('childname');
                                dispatch(push("/questionnaire/" + doctorid + "/" + nextModule + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email + "/" + nextId + "?methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                            }
                        }
                    }

                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        dispatch(hideLoading('sectionBar'))
                        if (moduleAreaId == "4") {
                            var langdata: any;
                            if (localStorage.getItem("LangResourcesValidations") != null)
                                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            toastr.success('', langdata['nochanges']);
                            //dispatch(actionCreators.requestFlexiForm(PatientId, ModuleName, "", Id, PatientId, recordid))
                           window.location.reload();
                           // dispatch(actionCreators.requestFlexiForm(PatientId, ModuleName, "", Id, recordid, "", moduleAreaId))

                        }
                        else {
                            if (nextModule == "") {
                                if (status == "Yes") {
                                    var patientstore: any = JSON.parse(patientLocalStroage);
                                    const urlParams = new URLSearchParams(window.location.search);
                                    const methodid = urlParams.get('methodid');
                                    if ((paidAmount == "0") || (paidAmount == "0.00") || (paidAmount == null)) {
                                        var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                        if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                            dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + AppointmentId + "&doctorid=" + doctorid + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
                                        } else {
                                            dispatch(_paymentProcess.requestScheduleUpdateFree(AppointmentId))
                                        }
                                    }

                                    else {
                                        var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                        if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                            dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + AppointmentId + "&doctorid=" + doctorid + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
                                        } else {

                                            var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                                            var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                                            var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];
                                            if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                                dispatch(push("/insurancelist?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                                //dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email))
                                            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                                dispatch(push("/paymentprocess/" + doctorid + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                                            } else {
                                                dispatch(push("/paymentprocess/" + doctorid + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                                            }
                                        }

                                    }
                                }

                                else {
                                    toastr.success('', langdata['nochanges']);
                                }
                            }
                            else {
                                var patientstore: any = JSON.parse(patientLocalStroage);
                                if (moduleAreaId == "6") {
                                   /* toastr.success('', 'Updated successfully');*/
                                    dispatch(actionCreators.requestFlexiForm(PatientId, ModuleName, "", null, recordid, "", moduleAreaId))
                                    dispatch(push("/initialpageforms/" + ModuleName + "/" + PatientId + "/" + recordid + "/" + null))
                                } else {
                                    // dispatch(actionCreators.requestFlexiForm(PatientId, nextModule, "", Id, recordid, "", moduleAreaId))
                                    dispatch(actionCreators.requestFlexiForm(PatientId, nextModule, "", nextId, recordid, localStorage.getItem("EncounterTypeId"), moduleAreaId))
                                    const urlParams = new URLSearchParams(window.location.search);
                                    const methodid = urlParams.get('methodid');
                                    const childid = urlParams.get('childid');
                                    const name = urlParams.get('childname');
                                    const childname = urlParams.get('childname');
                                    dispatch(push("/questionnaire/" + doctorid + "/" + nextModule + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email + "/" + nextId + "?methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                                }
                            }
                        }


                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ ...hideLoading('sectionBar') })
                });
            dispatch({ type: 'REQUEST_FORM_LIST' });
            dispatch({ ...hideLoading('sectionBar') })
        }


    },
    requestModuleForm: (moduleAreaId: any, patientId: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var accountId = localStorage.getItem("AccountId");


        const appState = getState();
        if (appState && appState.doctorLists) {
            axios.get('/FlexiForm/GetModuleList/' + accountId + "/" + moduleAreaId + "/" + patientId)
                .then(function (response) {
                    var formArray: any = [];
                    const result = response.data;
                    localStorage.setItem('customId', JSON.stringify(result));
                    result.forEach((element: any) => {
                        if ((element.id != null) && (element.showOnce == true)) {
                           // localStorage.setItem('customId', element.id)                          
                            return;
                        } else {
                            formArray.push(element);
                        }
                    });

                    //formArray = result.filter(function (el: any) {
                    //    return ((el.id == null) || (el.id != null) && (el.showOnce == false));
                    //});
                    dispatch({ type: 'RECEIVE_MODULE_LIST', moduleLists: formArray });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_MODULE_LIST' });
        }
    },
    getFormModule: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var accountid: any = localStorage.getItem("AccountId");
        const appState = getState();
        axios.get('/patient/GetFormModule/' + accountid + "/" + "6")
            .then(function (response) {
                const result = response.data;
                dispatch({ type: 'RECEIVE_MODULE_LIST', moduleLists: result });
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                dispatch(hideLoading('sectionBar'))
            })
            .then(function () {
                // always executed
            });
    },
    downloadFile: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        setTimeout(() => {
            window.location.href = "https://" + appState.siteDetail.siteDetail.HostName + "/InPatient/FileDownload/?filePath=" + value.defaultValue;
        }, 100)
    },
    requestSkipQuestions: (nextModule: any, status: any, nextId: any):
        AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
            const appState: any = getState();
            dispatch({ type: 'REQUEST_SKIP' });
            var date = localStorage.getItem("AppointmentOn");
            var time = localStorage.getItem("AppointmentTime");
            var accountid = localStorage.getItem("AccountId");
            var encDetailStroage: any = localStorage.getItem('EncDetail');
            var encDetail = JSON.parse(encDetailStroage);
            var _patientReferrralId = localStorage.getItem("patientReferrralId");
            var doctorid = localStorage.getItem("doctorid");
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patientstore = JSON.parse(patientLocalStroage);
            var AppointmentId = localStorage.getItem("AppointmentId");
            var paidAmount = localStorage.getItem("paidAmount");
            if (nextModule == "") {
                var patientstore: any = JSON.parse(patientLocalStroage);
                const urlParams = new URLSearchParams(window.location.search);
                const methodid = urlParams.get('methodid');
                if (status == "Yes") {
                    if ((paidAmount == "0") || (paidAmount == "0.00")|| (paidAmount == null)) {
                        var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                        if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                            dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + AppointmentId + "&doctorid=" + doctorid + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
                        } else {
                            dispatch(_paymentProcess.requestScheduleUpdateFree(AppointmentId))
                        }
                    }
                    else {
                        var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                        if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                            dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + AppointmentId + "&doctorid=" + doctorid + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
                        } else {

                            var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                            var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                            var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];
                            if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + name))
                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + name))
                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/insurancelist?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + name))
                            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + name))
                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                //dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email))
                            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                dispatch(push("/paymentprocess/" + doctorid + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                            } else {
                                dispatch(push("/paymentprocess/" + doctorid + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                            }
                        }
                    }
                }
                else {
                    var langdata: any;
                    if (localStorage.getItem("LangResourcesValidations") != null)
                        langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    toastr.success('', langdata['nochanges']);
                }
            }
            else {
                //window.location.href = "/questionnaire/?modulename=" + nextModule + "&doctorid=" + doctorid + "&appointmentid=" + AppointmentId + "&patientfirstName=" + patientstore.firstName + "&patientlastName=" + patientstore.lastName;
                dispatch(actionCreators.requestFlexiForm(patientstore.id, nextModule, "", nextId, patientstore.id, localStorage.getItem("EncounterTypeId"), "5"))
                const urlParams = new URLSearchParams(window.location.search);
                const methodid = urlParams.get('methodid');
                const childid = urlParams.get('childid');
                const name = urlParams.get('childname');
                const childname = urlParams.get('childname');
                dispatch(push("/questionnaire/" + doctorid + "/" + nextModule + "/" + AppointmentId + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email + "/" + nextId + "?methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))

            }
        }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: FlexiListsState = {
    formLists: [], isdocformLoading: false, dynamicForm: [], moduleLists: [], isskipLoading: false, isformLoading: false, ResultHtml: "",
    CustomFormSetting: [], isFormListLoading: false
};

export const reducer: Reducer<FlexiListsState> = (state: FlexiListsState | undefined, incomingAction: Action): FlexiListsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FORM_LIST':
            return {
                formLists: state.formLists,
                isdocformLoading: true,
                dynamicForm: state.dynamicForm,
                moduleLists: state.moduleLists,
                isskipLoading: false,
                isformLoading: true,
                ResultHtml: state.ResultHtml,
                isFormListLoading: false,
                CustomFormSetting: state.CustomFormSetting
            };
        case 'RECEIVE_FORM_LIST':
            return {
                formLists: action.formLists,
                isdocformLoading: false,
                dynamicForm: state.dynamicForm,
                moduleLists: state.moduleLists,
                isskipLoading: false,
                isformLoading: false,
                isFormListLoading: false,
                ResultHtml: action.ResultHtml,
                CustomFormSetting: action.CustomFormSetting
            };
        case 'RECEIVE_DYNAMICFORM_VALUES':
            return {
                formLists: state.formLists,
                isdocformLoading: false,
                dynamicForm: action.dynamicForm,
                moduleLists: state.moduleLists,
                isskipLoading: false,
                isformLoading: true,
                isFormListLoading: false,
                ResultHtml: state.ResultHtml,
                CustomFormSetting: state.CustomFormSetting,

            };
        case 'REQUEST_MODULE_LIST':
            return {
                formLists: state.formLists,
                isdocformLoading: true,
                dynamicForm: state.dynamicForm,
                moduleLists: state.moduleLists,
                isskipLoading: false,
                isformLoading: true,
                isFormListLoading: true,
                ResultHtml: state.ResultHtml,
                CustomFormSetting: state.CustomFormSetting,
            };
        case 'RECEIVE_MODULE_LIST':
            return {
                formLists: state.formLists,
                isdocformLoading: false,
                dynamicForm: state.dynamicForm,
                moduleLists: action.moduleLists,
                isskipLoading: false,
                isformLoading: true,
                isFormListLoading: false,
                ResultHtml: state.ResultHtml,
                CustomFormSetting: state.CustomFormSetting,
            }
        case 'REQUEST_SKIP':
            return {
                formLists: state.formLists,
                isdocformLoading: false,
                dynamicForm: state.dynamicForm,
                moduleLists: state.moduleLists,
                isskipLoading: true,
                isformLoading: false,
                isFormListLoading: false,
                ResultHtml: state.ResultHtml,
                CustomFormSetting: state.CustomFormSetting,
            }
        case 'REQUEST_NO_ACTION':
            return {
                formLists: state.formLists,
                isdocformLoading: false,
                dynamicForm: state.dynamicForm,
                moduleLists: state.moduleLists,
                isskipLoading: false,
                isformLoading: false,
                isFormListLoading: false,
                ResultHtml: action.ResultHtml,
                CustomFormSetting: action.CustomFormSetting,
            }

        default:
            return state;
    }
};
