import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { actionCreators as _navAction } from '../../store/menu/NavDefault';

export interface MemberSpecialityState {
    isLoading: boolean;
    isSpecialityLoading: boolean;
    startDateIndex?: number;
    speciality: Specialities[];
    doctorlistspeciality: DoctorListSpeciality[];
}
export interface DoctorListSpeciality {
    doctorId: string;
    prefix: string;
    suffix: string;
    gender: string;
    doctorName: string;
    accountName: string;
    location: string;
    accountId: string;
    serviceId: string;
    photo: string;
    facilityName: string;
    price: number;
    isPreferred: string;
    speciality: Speciality[];
    flag: boolean;
    minPrice: number;
    doctorServiceDetails: any;
}
export interface Speciality {
    id: string;
    name: string;
}
 export interface Specialities {
    id: string;
    name: string;
}


export interface RequestOurDoctorsNavAction { type: 'REQUEST_OURDOCTORS_NAV', startDateIndex: number, doctorlistspeciality: DoctorListSpeciality[], speciality: Specialities[] }
export interface ReceiveOurDoctorsNavAction { type: 'RECEIVE_OURDOCTORS_NAV', doctorlistspeciality: DoctorListSpeciality[], speciality: Specialities[] }


export interface RequestDoctorListsAction { type: 'REQUEST_DOCTORLIST_FORECAST', startDateIndex: number, doctorlistspeciality: DoctorListSpeciality[], speciality: Specialities[] }
export interface ReceiveDoctorListsAction { type: 'RECEIVE_DOCTORLIST_FORECAST', doctorlistspeciality: DoctorListSpeciality[], speciality: Specialities[]}




type KnownAction = RequestOurDoctorsNavAction | ReceiveOurDoctorsNavAction | RequestDoctorListsAction | ReceiveDoctorListsAction;

export const actionCreators = {
    requestMemberSpecialityLists: (): AppThunkAction<KnownAction> => (dispatch:any, getState:any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var languagecode: any = localStorage.getItem("DefaultLangCode");

        if (appState && appState.speciality && accountid != "") {
            axios.get('/doctor/GetMemberSpeciality/' + accountid + "/" + languagecode )
                .then(function (response) {
                    const users = response.data;
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_OURDOCTORS_NAV', speciality: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_OURDOCTORS_NAV' });
        }
    },
    requestSearchDoctorLists: (specialityid: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        var Specialityid = localStorage.getItem("SpecialityId") != null ? localStorage.getItem("SpecialityId") : specialityid;
        if (Specialityid == "")
            specialityid = null
        var Selectdate: any  = "-";             
        var doctorName = "-";        
        var gender = "-";
        if (appState && appState.doctorLists && accountid != "" && specialityid != undefined && specialityid != "" && specialityid != null) {
            dispatch({ type: 'REQUEST_DOCTORLIST_FORECAST', isSpecialityLoading : true });
            axios.get('/doctor/GetSearchDoctor/' + accountid + "/" + specialityid + "/" + Selectdate + "/" + doctorName + "/" + gender + "/" + languagecode)
                .then(function (response) {
                    const users = response.data;
                    localStorage.removeItem("AppointmentOn");
                    localStorage.removeItem("AppointmentTime");
                    localStorage.removeItem("AppointmentTime1");
                    localStorage.removeItem("AppointmentOn1");
                    localStorage.removeItem("AppointmentUTCTime")
                    localStorage.removeItem("AppointmentUTCTime1")
                    localStorage.removeItem("AppointmentBookedOn");
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_DOCTORLIST_FORECAST', doctorlistspeciality: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTORLIST_FORECAST' });
        }
    },
};


const unloadedState: MemberSpecialityState = {
    doctorlistspeciality: [],
    speciality: [],
    isSpecialityLoading: false,
    isLoading: false
};

export const reducer: Reducer<MemberSpecialityState> = (state: MemberSpecialityState | undefined, incomingAction: Action): MemberSpecialityState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_OURDOCTORS_NAV':
            return {
                speciality: state.speciality,
                doctorlistspeciality: state.doctorlistspeciality,
                isSpecialityLoading: false,
                isLoading: true
            };

        case 'RECEIVE_OURDOCTORS_NAV':
            return {
                speciality: action.speciality,
                doctorlistspeciality: state.doctorlistspeciality,
                isLoading: false,
                isSpecialityLoading: false
            };

        case 'REQUEST_DOCTORLIST_FORECAST':
            return {
                doctorlistspeciality: state.doctorlistspeciality,
                speciality: state.speciality,
                isSpecialityLoading: true,
                isLoading: true
            };

        case 'RECEIVE_DOCTORLIST_FORECAST':
            return {
                doctorlistspeciality: action.doctorlistspeciality,
                speciality: state.speciality,
                isSpecialityLoading: false,
                isLoading: false

            };
        default:
            return state;
    }
};
