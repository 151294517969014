import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import ContentLoader from 'react-content-loader';
import * as ContactStore from '../../../store/patient/Contact';
import * as HandleRequest from '../../../helper/Request';
import * as SiteDetailStore from '../../../store/SiteDetail';
import * as SiteMenusStore from '../../../store/siteaccount/siteMenu';
import { datepickerJS, floatingJS } from '../../../helper/Script';
import { ApplicationState } from '../../../store';
import { seo } from '../../../helper/Script';
import Moment from 'moment';
import * as AccountStore from '../../../store/siteaccount/Account';
import * as DoctorListStore from '../../../store/doctor/OurDoctors';
import * as CategoryStore from '../../../store/siteaccount/Category';
import { datePostFormat, setTittle, checkFamilyMember } from '../../../helper/formvalidation';
import * as MemberSpecialityStore from '../../../store/menu/speciality';
import * as SpecialityReasonStore from '../../../store/menu/specialityreason';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';
import { toastr } from 'react-redux-toastr';
type MemberSpecialityStoreProps = MemberSpecialityStore.MemberSpecialityState & SpecialityReasonStore.SpecialityReasonState & HandleRequest.Request & CategoryStore.CategoryState & AccountStore.AccountState & ContactStore.ContactState & SiteDetailStore.SiteDetailState & SiteMenusStore.SiteMenuState & LanguageResourcesStore.LanguageResouresState
    & typeof SiteDetailStore.actionCreators & typeof DoctorListStore.actionCreators & typeof MemberSpecialityStore.actionCreators & typeof SpecialityReasonStore.actionCreators & typeof CategoryStore.actionCreators & typeof AccountStore.actionCreators & typeof ContactStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof LanguageResourcesStore.actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;
var specialityid: any = "";
var specialityreasonid: any = "";
var specialityreasonname: any = "";
var specialityname: any = "";
var genderid: any = "";
var gendername: any = "";
var languagecontent: any = "";
var langdata: any = "";
var specialtybasedcondition: any = "";
var testreason: any = "";
var specReason: any = [];
var checkedValues: any = [];
declare var bootstrap: any;
var popup: any;
class MemberSpecialityMenu extends React.PureComponent<MemberSpecialityStoreProps> {
    selectEncType = (value: any) => {
        localStorage.setItem("SpecDetail", JSON.stringify({ Id: value.Id, Name: value.Name }))
        specialityid = value.id;
        specialityname = value.name;
        this.props.requestspecialityreason(value.id);
        if (localStorage.getItem("SpecialityId") != specialityid) {
            localStorage.removeItem("SpecailityReasonId");
        }
        localStorage.setItem("SpecialityId", specialityid);
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('name');
        if (name == "ourdoctors") {
            this.props.requestSearchDoctorLists(specialityid);
        }
    }
    selectEncTypeReason = (e:any, value: any) => {
        localStorage.setItem("SpecReason", JSON.stringify({ Id: value.Id, Reason: value.Reason }))
        specialityreasonid = value.id;
        specialityreasonname = value.reason;
        
       
        //localStorage.setItem("SpecailityReasonId", value.id);
        //for (let i = 0; i < specialityreasonid.length ; i++) {
        //    arr.push(value.id);
        //}
        //console.log(value);
        if (e.target.checked == true) {
            //var data1: any = [];
            //var data2: any = [];
            //var id:any =""
            //data1 = checkedValues.filter((value: any) => (value.id == id));
            //data1.forEach((value: any) => {
            //    data2.push(value.id)
            //    testreason = data2;
            specReason.push({ReasonId:value.id, Reason: value.reason })
            testreason = specReason;            
            }
                
        else {
            
            specReason.splice(specReason.indexOf(value.id),1)
            //var filterreason: any = ""
            //var filterreason: any = arr.filter((x: any) => x != value.id)
        }
        //console.log(testreason)
        //console.log(specReason.map((item: any) => item).join(', '))
    }
    
    saveReason = () => {
        localStorage.setItem("SpecailityReasonId", JSON.stringify(specReason))
        if (specialityreasonname != "") {
            localStorage.setItem("SpecailityReasonName", specialityreasonname);
        }
        else {
            localStorage.removeItem("SpecailityReasonName");
        }
        /*console.log(specReason[0].Reason);*/
        //console.log(specialityreasonname);
        //console.log(specReason.map((item: any) => item).join(', '))
        var checkboxes: any = document.getElementsByName('specialityreason');
        for (var checkbox of checkboxes) {
            checkbox.checked = false;
        }
         if(window.document.getElementById('emergencyModalCenter')) {
            setTimeout(function () {
                var myModal = new bootstrap.Modal(window.document.getElementById('emergencyModalCenter'), {
                    backdrop: 'static',
                    keyboard: false
                })
                myModal.show();
            }, 100);
        }
    

    }
    cancelReason = () => {
        var checkboxes: any = document.getElementsByName('specialityreason');
        for (var checkbox of checkboxes) {
            checkbox.checked = false;
        }
    }
    closePopup = () => {
        
            //opener.location.reload(true);
            //self.close();
        
    }
        //var arr: any = [];

        //let checkboxes = document.querySelectorAll("input[type='checkbox']:checked");

        //checkboxes.forEach((value) => {

        //    console.log(value)
        //    arr.push(value)
        //})
        //console.log(checkboxes)}

    //    //console.log(arr);
    //    let checkboxes = document.querySelectorAll("input[type='checkbox']:checked");
    //    for (let i = 0; i < checkboxes.length; i++) {
    //        var check: any = checkboxes[i]
    //        arr.push(check.value.id);
    //    }
    //    // Converted array to string & alert
    //    alert(arr.toString());
    //}

//   var i : any ="", checkboxes = document.querySelectorAll('input[type=checkbox]');

//function save(value:any) {
//    for (i = 0; i < checkboxes.length; i++) {
//        localStorage.setItem(checkboxes[i].value, checkboxes[i].checked);
//    }
//}

//function load_() {
//    for (i = 0; i < checkboxes.length; i++) {
//        checkboxes[i].checked = localStorage.getItem(checkboxes[i].value) === 'true' ? true : false;
//    }
//}
//  var myArray: = [];
//let checkboxes = document.querySelectorAll('input[type="checkbox"]');
//checkboxes.forEach(function (checkbox) {
//    if (checkbox.checked) {
//        myArray.push(checkbox.value);
//    }
//});
    /*specialtybasedcondition: any = this.props.Settings.filter((s: any) => s.name =="Specialty Based Condition");*/
    
    onSubmit = (formValues: any) => {
        if (localStorage.getItem("SpecialityId") != undefined && localStorage.getItem("SpecialityId") != null && localStorage.getItem("SpecialityId") != "") {
            popup = this.props.specialityReason;
            if (popup.specialityReason.length != 0 && (localStorage.getItem("SpecailityReasonId") == "" || localStorage.getItem("SpecailityReasonId") == null)) {
                if (window.document.getElementById('myModal')) {
                    setTimeout(function () {
                        var myModal = new bootstrap.Modal(window.document.getElementById('myModal'), {
                            backdrop: 'static',
                            keyboard: false
                        })
                        myModal.show();
                    }, 100);
                }
                return;
            }
            else if (popup.isEmergency == true) {
                if (window.document.getElementById('emergencyModalCenter')) {
                    setTimeout(function () {
                        var myModal = new bootstrap.Modal(window.document.getElementById('emergencyModalCenter'), {
                            backdrop: 'static',
                            keyboard: false
                        })
                        myModal.show();
                    }, 100);
                }

                return;
            }
            /*if (window.document.getElementById) { new bootstrap.Modal(window.document.getElementById('emergencyModalCenter')).style.visibility = 'hidden'; }*/
            const urlParams = new URLSearchParams(window.location.search);
            const name = urlParams.get('name');
            var myForm: any = document.getElementById("dformsearchdoctor");
            if (myForm.elements.namedItem("selectdate") != null) {
                formValues.Selectdate = myForm.elements.namedItem("selectdate").value;
            }
            if (myForm.elements.namedItem("doctorName") != null) {
                formValues.doctorName = myForm.elements.namedItem("doctorName").value;
            }
            formValues.gender = genderid;
            formValues.specialityid = specialityid;
            if (formValues.specialityid == "" && localStorage.getItem("SpecialityId"))
                formValues.specialityid = localStorage.getItem("SpecialityId");
            localStorage.setItem("SpecialityId", formValues.specialityid);
            if (formValues.Selectdate != null && formValues.Selectdate != "") {
                var insurancedate: any = datePostFormat(formValues.Selectdate);
                var _datetime = Moment(new Date(insurancedate)).format('YYYY-MM-DD');
                formValues.Selectdate = _datetime;
            }
            var langdata: any;
            if (localStorage.getItem("LangResourcesValidations") != null)
                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
            if (formValues.specialityid == "") {
                return toastr.error('', langdata['pleaseselectspeciality']);
            }
            const childid = urlParams.get('childid');
            //const parentid = urlParams.get('parentid');
            const patientid = urlParams.get('patientid') || urlParams.get('patientId');
            const childname = urlParams.get('childname');
            if (name == "centralized") {
                if (childid == null)
                    window.location.href = "/centralized?specialityid=" + formValues.specialityid;
                else
                    window.location.href = "/centralized?childid=" + childid + "&childname=" + childname + "&specialityid=" + formValues.specialityid;
            } else if (name == "consultnow") {
                if (localStorage.getItem('isAuth') == "true") {
                    if (childid == null) {
                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + formValues.specialityid)
                        localStorage.setItem("pathName", "RequestQueue");
                        var result: any = JSON.parse(localStorage.getItem("Patient") || "");
                        if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                            localStorage.setItem("pathName", "RequestQueue")
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + formValues.specialityid);
                            window.location.href = "/contact/" + null + "/" + result.memberId + "?methodid=5" + "&childid=" + childid + " &childname=" + childname;
                        } else {
                            window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + formValues.specialityid;
                        }
                    }
                    else {
                        var result: any = JSON.parse(localStorage.getItem("Patient") || "");
                        if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                            localStorage.setItem("pathName", "RequestQueue")
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + childid + "&childname=" + childname + "&type=noappointment&specialityid=" + formValues.specialityid);
                            window.location.href = "/contact/" + null + "/" + result.memberId + "?methodid=5" + "&childid=" + childid + " &childname=" + childname;
                        } else {
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + childid + "&childname=" + childname + "&type=noappointment&specialityid=" + formValues.specialityid)
                            localStorage.setItem("pathName", "RequestQueue");
                            window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + childid + "&childname=" + childname + "&type=noappointment&specialityid=" + formValues.specialityid;
                        }
                    }
                }
                else {
                    localStorage.setItem("pathName", "RequestQueue")
                    localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&serviceid=&type=noappointment&specialityid=" + formValues.specialityid);
                    window.location.href = "/login";
                }
            }
            else {
                if (childid == null) {
                    var doctorlistspeciality: any = this.props.doctorlistspeciality;
                    if (doctorlistspeciality.length == 1) {
                        var doctorid: any = this.props.doctorlistspeciality[0].doctorId;
                        window.location.href = "/booking/" + doctorid + "?specialityid=" + formValues.specialityid;

                    } else {
                        window.location.href = "/ourdoctors?specialityid=" + formValues.specialityid;
                    }
                }
                else {
                    var doctorlistspeciality: any = this.props.doctorlistspeciality;
                    if (doctorlistspeciality.length == 1) {
                        var doctorid: any = this.props.doctorlistspeciality[0].doctorId;
                        window.location.href = "/booking/" + doctorid + "?childid=" + childid + "&childname=" + childname + "&specialityid=" + formValues.specialityid;

                    } else {
                        window.location.href = "/ourdoctors?childid=" + childid + "&childname=" + childname + "&specialityid=" + formValues.specialityid;
                    }
                }
            }
        }
    }
    agreeBtn = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('name');
        const childid = urlParams.get('childid');
        //const parentid = urlParams.get('parentid');
        const patientid = urlParams.get('patientid') || urlParams.get('patientId');
        const childname = urlParams.get('childname');
        if (localStorage.getItem("SpecialityId") != "")
            specialityid = localStorage.getItem("SpecialityId");
        if (name == "centralized") {
            if (childid == null)
                window.location.href = "/centralized?specialityid=" + specialityid;
            else
                window.location.href = "/centralized?childid=" + childid + "&childname=" + childname + "&specialityid=" + specialityid;
        } else if (name == "consultnow") {
            if (localStorage.getItem('isAuth') == "true") {
                if (childid == null) {
                    localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + specialityid)
                    localStorage.setItem("pathName", "RequestQueue");
                    var result: any = JSON.parse(localStorage.getItem("Patient") || "");
                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                        localStorage.setItem("pathName", "RequestQueue")
                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + specialityid);
                        window.location.href = "/contact/" + null + "/" + result.memberId + "?methodid=5" + "&childid=" + childid + " &childname=" + childname;
                    } else {
                        window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + specialityid;
                    }
                }
                else {
                    var result: any = JSON.parse(localStorage.getItem("Patient") || "");
                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                        localStorage.setItem("pathName", "RequestQueue")
                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + childid + "&childname=" + childname + "&type=noappointment&specialityid=" + specialityid);
                        window.location.href = "/contact/" + null + "/" + result.memberId + "?methodid=5" + "&childid=" + childid + " &childname=" + childname;
                    } else {
                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + childid + "&childname=" + childname + "&type=noappointment&specialityid=" + specialityid)
                        localStorage.setItem("pathName", "RequestQueue");
                        window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + childid + "&childname=" + childname + "&type=noappointment&specialityid=" + specialityid;
                    }
                }
            }
            else {
                localStorage.setItem("pathName", "RequestQueue")
                localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&serviceid=&type=noappointment&specialityid=" + specialityid);
                window.location.href = "/login";
            }
        }
        else {
            if (childid == null) {
                var doctorlistspeciality: any = this.props.doctorlistspeciality;
                if (doctorlistspeciality.length == 1) {
                    var doctorid: any = this.props.doctorlistspeciality[0].doctorId;
                    window.location.href = "/booking/" + doctorid + "?specialityid=" + specialityid;

                } else {

                    window.location.href = "/ourdoctors?specialityid=" + specialityid;
                }
            }
            else {
                var doctorlistspeciality: any = this.props.doctorlistspeciality;
                if (doctorlistspeciality.length == 1) {
                    var doctorid: any = this.props.doctorlistspeciality[0].doctorId;
                    window.location.href = "/booking/" + doctorid + "?childid=" + childid + "&childname=" + childname + "&specialityid=" + specialityid;

                } else {
                    window.location.href = "/ourdoctors?childid=" + childid + "&childname=" + childname + "&specialityid=" + specialityid;
                }
            }
        }
    }
    constructor(props: any) {
        super(props);
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));

    }
    public componentDidMount() {      
        localStorage.removeItem("speaktovet");
        localStorage.removeItem("EncDetail");
        localStorage.removeItem("Appointment");
        localStorage.removeItem("AppointmentTime1");
        localStorage.removeItem("AppointmentOn");
        localStorage.removeItem("AppointmentOn1");
        localStorage.removeItem("AppointmentTime");
        localStorage.removeItem("AppointmentUTCTime")
        localStorage.removeItem("AppointmentUTCTime1")
        localStorage.removeItem("SelectedDate");
         localStorage.removeItem("Discount");
        localStorage.removeItem("DiscountId");
        localStorage.removeItem("DiscountAmount");
        localStorage.removeItem("TotalAmount");
        localStorage.removeItem("ServiceEncounterTypeId");
        localStorage.removeItem("FinalEncounterName");
        this.ensureDataFetched();
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        datepickerJS();
        floatingJS();
        checkFamilyMember()
        var specialityIdz: any = localStorage.getItem("SpecialityId");
        if (specialityIdz == null) {
            specialityIdz=""
        }

        if (specialityIdz != "" || specialityIdz != null || specialityIdz != "null") {
            this.props.requestspecialityreasons(specialityIdz);
            const urlParams = new URLSearchParams(window.location.search);
            const name = urlParams.get('name');
            if (name == "ourdoctors") {
                this.props.requestSearchDoctorLists(specialityIdz);
            }

        }       
    }
   
    private ensureDataFetched() {
        this.props.getSiteSetting(null);
        this.props.getLangResources();
        this.props.requestMemberSpecialityLists();
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        /*this.props.requestspecialityreason();*/
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderOurDoctorsNav()}
            </React.Fragment>
        );
    }
    
    
    public renderOurDoctorsNav() {        
        if (this.props.Settings.length != 0)
            specialtybasedcondition = this.props.Settings.filter((s: any) => s.name == "Specialty based condition")[0];
        languagecontent = this.props.LanguageResoures;
        const specialtyid = localStorage.getItem("SpecialityId");
        //console.log(JSON.stringify(this.props.specialityReason, ['emergencyDescription']))
        popup = this.props.specialityReason;       
        $(document).keydown(function (e) {
            e.preventDefault();
        });
        return (           
            <div className="content" style = {{padding : "50px 0px"}}>
                <div className="container-fluid">
                    <React.Fragment>
                        <div className="col-xl-12">

                            <form onSubmit={this.props.handleSubmit(this.onSubmit)} id="dformsearchdoctor" noValidate autoComplete={"off"}>
                                <div>
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                this.props.speciality.length != 0 ?
                                                    <div>
                                                        <h4 className="widget-title pat-widget-title ng-binding">{languagecontent['findyourspecialist']}</h4>
                                                        <br />
                                                        <div className="row">
                                                            {
                                                                this.props.speciality.length != 0 ?
                                                                    this.props.speciality.map((speciality: MemberSpecialityStore.Specialities, index) =>

                                                                        <div className="col-sm-4 col-xs-12 mb-3">
                                                                            <li className="list-group-item align-items">
                                                                                <div className="avatar avatar-sm">
                                                                                    <label className="custom_radio">
                                                                                        <div className="cp_spec_width">
                                                                                            <input type="radio" name="speciality" onClick={() => this.selectEncType(speciality)} defaultChecked={speciality.id == specialtyid ? true : false} value="Ccon" /><span className="checkmark"></span> {speciality.name}
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </div>

                                                                    )
                                                                    : ""
                                                            }

                                                        </div>
                                                    </div>


                                                    :
                                                    <React.Fragment>
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader> <br />
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader><br />
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader><br />
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader><br />
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader><br />
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader>
                                                        <br />
                                                        <ContentLoader
                                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            height={54}
                                                            width={320}
                                                            viewBox="0 0 320 54"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <circle cx="27" cy="27" r="18" />
                                                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                            <circle cx="305" cy="27" r="8" />
                                                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                        </ContentLoader>
                                                    </React.Fragment>


                                            }
                                        </div>
                                        
                                    </div>

                                    {
                                        this.props.speciality.length != 0 ?
                                            <div className="clinic-booking  submit-section  proceed-btn">
                                                <button className="btn btn-primary submit-btn float-right" type="submit" disabled={this.props.isLoading || this.props.isSpecialityLoading}>{this.props.isLoading == true || this.props.isSpecialityLoading == true ? <img src="../../../buttonloader.svg" /> : languagecontent['proceedbutton']}</button>
                                            </div>: ""
                                            
                                    }
                                                             

                                    
                                    {
                                        popup.specialityReason != undefined ?

                                            popup.specialityReason.length != 0 ?

                                                specialtybasedcondition.customizeValue == "Yes" ?


                                                    <div className="modal fade" data-backdrop="static" id="myModal" aria-hidden="true" role="dialog" data-keyboard="true">



                                                    <div className="modal-dialog modal-dialog-centered" role="document" >

                                                        <div className="modal-content">



                                                            <div className="modal-header">

                                                                <h5 className="modal-title">{languagecontent['conditions']}</h5>

                                                            </div>



                                                            <div className="modal-body">



                                                                {

                                                                        popup.specialityReason.map((specialityReason: SpecialityReasonStore.SpecialitiesReason, index: any) =>

                                                                            <div><label className="custom_radio"><div className="cp_spec_width"><input type="radio" name="specialityreason" onChange={(e: any) => this.selectEncTypeReason(e, specialityReason)} defaultChecked={specialityReason.id == specialityreasonid ? true : false} value="Ccon" />

                                                                                <span className="checkmark" style={{ paddingLeft: "10px" }}></span>{specialityReason.reason}</div></label>

                                                                            </div>
                                                                    )

                                                                }

                                                            </div>

                                                            <div className="modal-footer">

                                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.cancelReason()}>{languagecontent['cancel']}</button>

                                                                    <button type="button" className="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#myModal1" onClick={() =>  this.saveReason()}>{languagecontent['continue']}</button>

                                                            </div>



                                                        </div>

                                                    </div>

                                                </div> : "" : "":""

                                    }
                                    { popup.isEmergency == true ?
                                        <React.Fragment>
                                            <div className="modal fade" id="emergencyModalCenter" role="dialog" data-backdrop="static" aria-labelledby="EmergencyModalCenterTitle" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="text-center">
                                                            <i className="fas fa-exclamation-circle fa-rotate-180" style={{ fontSize: "75px", marginTop:"25px"}}></i>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div >
                                                                <span dangerouslySetInnerHTML={{ __html: popup.emergencyDescription }}></span>
                                                               
                                                                </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" onClick={() => this.agreeBtn()} className="btn btn-primary" disabled={this.props.isLoading || this.props.isSpecialityLoading} > {this.props.isLoading == true || this.props.isSpecialityLoading == true ? <img src="../../../buttonloader.svg" /> : languagecontent['agree']}</button>
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal" ><button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            </button>{languagecontent['cancel']}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment> : ""
                                        

                                    }
                                    
                                    

                                </div>
                            </form>
                            
                        </div>
                    </React.Fragment>
                </div>
            </div>
            
        );
        
    }
    
}
const mapDispatchToProps = ({ ...MemberSpecialityStore.actionCreators, ...SpecialityReasonStore.actionCreators, ...CategoryStore.actionCreators, ...AccountStore.actionCreators, ...ContactStore.actionCreators, ...SiteDetailStore.actionCreators, ...SiteMenusStore.actionCreators, ...LanguageResourcesStore.actionCreators });
const mapStateToProps = (state: ApplicationState) => {
    let values = {
        ...state.memberSpeciality, ...state.speciality, ...state.specialityreason, ...DoctorListStore.actionCreators, ...state.contact, ...state.siteDetail, ...state.siteMenus, ...state.langResources, ...state.category, ...state.account
    };
    return values;

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'dformsearchdoctor',
    enableReinitialize: true,
})(MemberSpecialityMenu as any))




