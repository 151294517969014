import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import * as ProfileStore from '../../store/patient/ProfileSettings';
import * as InsuranceAutoCompleStore from '../../store/autoComplete/insurance';
import * as LoginStore from '../../store/account/Login';
import * as HandleRequest from '../../helper/Request';
import * as CategoryStore from '../../store/siteaccount/Category';
import * as AddInsuranceStore from '../../store/insurance/addInsurance';
import * as AccountStore from '../../store/siteaccount/Account';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { datepickerJS, floatingJS, floatJS, dobcheck, mobnav } from '../../helper/Script';
import { seo } from '../../helper/Script';
import { Field, reduxForm } from 'redux-form';
import { renderDob, renderExpDob, renderField, renderInsuranceautoComplete, renderInsuranceSelectField, renderInsurenceDob, renderSelectField } from '../../helper/renderfield';
import { dateFormat, dateFormatSetting, getAge, required } from '../../helper/formvalidation';
import * as  documentStore from '../../store/library/document/DocumentList';
import * as  documentUploadStore from '../../store/library/document/DocumentUpload';
import DocumentList from '../library/document/DocumentList';
import DocumentUpload from '../library/document/DocumentUpload';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { toastr } from 'react-redux-toastr';
import Moment from 'moment';
import { TextBoxLoader } from '../../helper/fbloader';
import { setTittle, scrollTop } from '../../helper/formvalidation'
import ContentLoader from 'react-content-loader';

var _insurance: any = {};
type ProfileProps =
    ProfileStore.ProfileState & LoginStore.LoginState & CategoryStore.CategoryState & InsuranceAutoCompleStore.InsuranceAutoCompleteState & documentStore.DocumentListsState & AddInsuranceStore.AddInsuranceState & documentUploadStore.DocumentUploadState & HandleRequest.Request & AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState & typeof ProfileStore.actionCreators & typeof CategoryStore.actionCreators & typeof InsuranceAutoCompleStore.actionCreators & typeof documentStore.actionCreators & typeof AddInsuranceStore.actionCreators & typeof documentUploadStore.actionCreators & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators &
    RouteComponentProps<{}>;
var insuranceid: any = "";
var insurancename: any = "";
var insureddob: any = "";
var expdate: any = "";
var insurancenameChange: any = "";
var languagecontent: any = "";
class InsurenceForm extends React.PureComponent<ProfileProps> {
    constructor(props: any) {
        super(props);
    }
    textInput = React.createRef();
    onSubmit = (formValues: any) => {
        var langdata: any;
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        /* Validation for Insurance name and Id -- start by Arun */
        var myForm: any = document.getElementById("dformInsurance");
        if (myForm.elements.namedItem("InsuredDOB") != null) {
            formValues.InsuredDOB = myForm.elements.namedItem("InsuredDOB").value;
        }
        if (myForm.elements.namedItem("ExpiryDate") != null) {
            formValues.ExpiryDate = myForm.elements.namedItem("ExpiryDate").value;
        }
        if (formValues.Id == "") {
            formValues.VerificationStatusId = null;
            formValues.VerificationStatus = null;
            formValues.PlatformType = "CP";
        }
        else {
            formValues.VerificationStatusId = null;
            formValues.VerificationStatus = null;
            formValues.PlatformType = "CP";
        }
        
        var age: any = getAge(formValues.InsuredDOB);
        var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
        var date: any = (formValues.InsuredDOB);
        const dmyear = date.split("/");
        const year = dmyear[2];
        if (year == ("0000" || "000" || "00" || "0") || age >=250) {
            var langdata: any;
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
            return toastr.error('', langdata['invaliddate']);
        }
        //console.log(formValues);
        if (doblimit.customizeValue > age) {
            return toastr.error('', langdata['userdobrange'] + ' ' + + doblimit.customizeValue + ' ' + langdata['years']);
        }
        const insuranceFilter = this.props.InsuranceAutoCompletes.filter(function (value) {
            return value;
        })
        if (insuranceFilter.length > 0) {
            if (insuranceid == "") {
                formValues.InsuranceId = "";
                var Insurancevalue: any = document.getElementById("dInsurenceName")
                if (Insurancevalue != null) {
                    formValues.InsuranceName = Insurancevalue.value;
                }
                var array: any = [];
                var doids: any = localStorage.getItem("DocIds");
                if (doids != null) {
                    array = JSON.parse(doids);
                }
                formValues.DocIds = array.length == 0 ? "" : array.toString();
                //formValues.StatusId = "1"; 
                var StatusId: any = formValues.StatusId == "" ? "1" : formValues.StatusId
                this.props.postInsurance(formValues, "", StatusId);
            }
            else {
                formValues.InsuranceId = insuranceid == "" ? _insurance.InsuranceId : insuranceid;
                var insurancenames: any = insuranceFilter.filter((se: any) => se.Id == formValues.InsuranceId)
                if (insurancenames.length != 0)
                    formValues.InsuranceName = insurancenames[0].Name;
                var array: any = [];
                var doids: any = localStorage.getItem("DocIds");
                if (doids != null) {
                    array = JSON.parse(doids);
                }
                formValues.DocIds = array == null || array.length == 0 ? "" : array.toString();
               var  StatusId :any = formValues.StatusId == "" ? "1" : formValues.StatusId
                this.props.postInsurance(formValues, "", StatusId);
            }
        }
        else {
            this.props.requestInsurance(formValues.InsuranceId)
            const insuranceFilter = this.props.InsuranceAutoCompletes.filter(function (value) {
                return value;
            })
            //console.log(insuranceFilter, "insuranceFilter", formValues.InsuranceId)
            if (insuranceFilter.length > 0) {
                //console.log(insuranceFilter.length, "length")
                formValues.InsuranceId = insuranceid;
                var array: any = [];
                var doids: any = localStorage.getItem("DocIds");
                if (doids != null) {
                    array = JSON.parse(doids);
                }
                formValues.DocIds = array.length == 0 ? "" : array.toString();
                //formValues.StatusId = "1";         
                var StatusId: any = formValues.StatusId == "" ? "1" : formValues.StatusId

                this.props.postInsurance(formValues, "", StatusId);
            }
            else {
                formValues.InsuranceId = insuranceid;
                var Insurancevalue: any = document.getElementById("dInsurenceName")
                if (Insurancevalue != null) {
                    formValues.InsuranceName = Insurancevalue.value;
                }
                var array: any = [];
                var doids: any = localStorage.getItem("DocIds");
                if (doids != null) {
                    array = JSON.parse(doids);
                }
                formValues.DocIds = array.length == 0 ? "" : array.toString();
                //formValues.StatusId = "1";
                if (formValues.Id == "") {
                    var StatusId: any = formValues.StatusId == "" ? "1" : formValues.StatusId
                    this.props.postInsurance(formValues, "", StatusId);
                }
                else {
                    var StatusId: any = formValues.StatusId == "" ? "1" : formValues.StatusId
                    this.props.postInsurance(formValues, "", StatusId);
                }
            }
        }
        /* Validation for Insurance name and Id -- end by Arun */
    }
   
        //const queryParams = new URLSearchParams(window.location.search);
        //var id = queryParams.get('id');
        //var memberid = queryParams.get('memberid');
        //if (id != null) {
        //    this.props.getSingleInsurance(id);
        //    this.props.requestDocumentLists(memberid, "Patient Document");
        //}

        //if (insureddob == null)
        //	dobcheck(dobcheck(dateFormat(this.props.addInsurance.InsuredDOB), dobchk));
        //else
        //	dobcheck(dobcheck(dateFormat(insureddob), dobchk));

        //if (expdate == null)
        //	dobcheck(dobcheck(dateFormat(this.props.addInsurance.InsuredDOB), dobchk));
        //else
        //	dobcheck(dobcheck(dateFormat(expdate), dobchk));
        /*this.ensureDataFetched()*/


 
    componentDidMount() {
        //seo({
        //    title: 'Insurance',
        //    pageTitle: 'Insurance',
        //    description: ''
        //});
        //var dobchk = "";
        floatingJS();
        datepickerJS();
        dobcheck("", "");
        mobnav();
        scrollTop();
        insurancename = localStorage.getItem("InsuranceName");
        insureddob = localStorage.getItem("InsuredDOB");
        expdate = localStorage.getItem("ExpiryDate");
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
       
        var dobchk = "";
        //if (insureddob == null)
        //	dobcheck(dobcheck(dateFormat(this.props.addInsurance.InsuredDOB), dobchk));
        //else
        //	dobcheck(dobcheck(dateFormat(insureddob), dobchk));

        //if (expdate == null)
        //	dobcheck(dobcheck(dateFormat(this.props.addInsurance.InsuredDOB), dobchk));
        //else
        //	dobcheck(dobcheck(dateFormat(expdate), dobchk));

        this.ensureDataFetched()
    }

    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));

    }

    public _handleSearch = (query: any) => {
        //console.log("query", query.length)
        insuranceid = "";
        this.props.requestInsurance(query);
    }
    public _handleOnChange = (selected: any) => {
        //console.log(selected, "selected")
        selected.map((value: any) => {
            insuranceid = value.Id;
        })
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.getCategory();
        const queryParams = new URLSearchParams(window.location.search);
        var id = queryParams.get('id');
        var memberid = queryParams.get('memberid');
        //if (id != null) {
        //	this.props.getSingleInsurance(id);
        //	this.props.requestDocumentLists(memberid, "Patient Document");
        //}
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderInsurenceList()}
            </React.Fragment>
        );
    }
    goBack = () => {
        this.props.goBackInsurance();
    }
    public renderInsurenceList() {
        languagecontent = this.props.LanguageResoures;
        var dateformat: any = localStorage.getItem("DateFormat") == null ? "" : localStorage.getItem("DateFormat");
        const queryParams = new URLSearchParams(window.location.search);
        var id = queryParams.get('id');
        var memberid = queryParams.get('memberid');
        _insurance = this.props.addInsurance;
        insurancename = localStorage.getItem("InsuranceName");
        insureddob = localStorage.getItem("InsuredDOB");
        expdate = localStorage.getItem("ExpiryDate");
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        //if (this.props.addInsurance.InsuredDOB == "") {
        //	this.props.addInsurance.InsuredDOB = null;
        //} else {
        //	var __dateformat: any = dateFormatSetting(this.props.addInsurance.InsuredDOB);
        //	if (__dateformat == "Invalid date" || __dateformat == "Fecha inválida") {
        //		this.props.addInsurance.InsuredDOB = null;
        //	} else {
        //		console.log(this.props.addInsurance.InsuredDOB);
        //		this.props.addInsurance.InsuredDOB = dateFormatSetting(this.props.addInsurance.InsuredDOB);
        //	}
        //}


        //if (this.props.addInsurance.InsuredDOB == "") {
        //	/*this.props.addInsurance.InsuredDOB = "";*/
        //}
        //else {
        //	var __dateformat: any = dateFormatSetting(this.props.addInsurance.InsuredDOB);
        //	if (__dateformat == "Invalid date" || __dateformat == "Fecha inválida") {


        //	} else {				
        //		console.log(this.props.addInsurance.InsuredDOB, )

        //		/*this.props.addInsurance.InsuredDOB = dateFormatSetting(this.props.addInsurance.InsuredDOB)*/
        //		/*this.props.addInsurance.InsuredDOB = __dateformat*/

        //		_insurance.InsuredDOB = __dateformat

        //	}
        //}
        //if (this.props.addInsurance.ExpiryDate == "") {
        //	/*this.props.addInsurance.ExpiryDate = "";*/
        //}
        //else {
        //	var __dateExpformat: any = dateFormatSetting(this.props.addInsurance.ExpiryDate);
        //	if (__dateExpformat == "Invalid date" || __dateExpformat == "Fecha inválida") {


        //	} else {
        //		/*this.props.addInsurance.ExpiryDate = dateFormatSetting(this.props.addInsurance.ExpiryDate);*/
        //		/*this.props.addInsurance.ExpiryDate = __dateExpformat;*/
        //		_insurance.ExpiryDate = __dateExpformat
        //		/*console.log(__dateExpformat)*/
        //	}
        //}

        /*	console.log(_insurance)*/

        //if (Moment(this.props.addInsurance.InsuredDOB).format('MM/DD/YYYY') == "Invalid date")
        //	this.props.addInsurance.InsuredDOB = "";
        //else
        //	this.props.addInsurance.InsuredDOB = dateFormatSetting(this.props.addInsurance.InsuredDOB);

        //if (Moment(this.props.addInsurance.ExpiryDate).format('MM/DD/YYYY') == "Invalid date")
        //	this.props.addInsurance.ExpiryDate = "";
        //else
        //	this.props.addInsurance.ExpiryDate = dateFormatSetting(this.props.addInsurance.ExpiryDate);


        /*console.log(_insurance)*/
        const insurancetype = this.props.categories.filter((ca: any) => ca.group == "Insurance Type");

        if (id) {
            var localLocation = insurancename == "" || insurancename == null ? "" : insurancename;
        }
        else {
            localLocation = "";
        }

        const insurance = this.props.InsuranceAutoCompletes;
        const isLoading = this.props.isinsuranceLoading;
        const onSearch = this._handleSearch;
        const onChangeMethod = this._handleOnChange;
        var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
        var _doblimit: any = 0;
        if (doblimit != undefined) {
            _doblimit = doblimit.customizeValue;
        } else {
            _doblimit = 0;
        }
        var data: any = window.location.pathname;
        const _array = data.split('/');
        localStorage.setItem("Documents", "true");
        return (
            <React.Fragment>

                {this.props.isListLoading == true ?
                    <ContentLoader viewBox="0 0 400 100">
                        <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                        <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                        <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                    </ContentLoader> :
                    <React.Fragment>
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} id="dformInsurance" noValidate autoComplete={"off"}>


                            <div className="card">
                                <div className="card-body">
                                    <div className="row form-row">
                                        <div className="col-12 col-md-6">
                                            {
                                                languagecontent['insurancetype'] == undefined ? <TextBoxLoader /> : ""
                                            }
                                            {
                                                <div className={languagecontent['insurancetype'] == undefined ? "form-group form-focus focused hide" : "form-group form-focus focused"}>
                                                    <Field name="Level" options={insurancetype} select={languagecontent['addinsuranceselect']} component={renderInsuranceSelectField} validate={[required]} className="form-control" />
                                                    <label className="focus-label" style={{ background: "transparent" }}>{languagecontent['insurancetype']}</label>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.InsuranceId.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <Field name="InsuranceId" Location={insurance} maxLength={100} autoCompleteData={localLocation} isLoading={isLoading}onSearch={onSearch} id="dInsuranceName" onChangeMethod={onChangeMethod} onClickMethod={this._handleSearch} component={renderInsuranceautoComplete} label={languagecontent['insurancename']} validate={[required]} type="text" /></div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.InsuredName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <Field name="InsuredName" component={renderField} label={languagecontent['insuredname']} maxLength={150} validate={[required]} type="text" /></div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.InsuredDOB ? "form-group form-focus focused" : "form-group form-focus"} >
                                                <div className={languagecontent['insureddob'] == undefined ? "cal-icon hide" : "cal-icon"}>
                                                    <Field name="InsuredDOB" component={renderInsurenceDob} label={languagecontent['insureddob'] == undefined ? "" : languagecontent['insureddob'] + " " + "(" + dateformat + ")"} maxLength={15} type="text" loading={this.props.isListLoading} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.PolicyNo.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <Field name="PolicyNo" component={renderField} label={languagecontent['insuranceorpolicy']} validate={[required]} maxLength={25} minLength={3} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.GroupNo.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <Field name="GroupNo" component={renderField} label={languagecontent['insurancegroupno']} maxLength={25} minLength={3} type="text" /></div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.PlanName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <Field name="PlanName" component={renderField} label={languagecontent['insuranceplan']} maxLength={150} type="text" /></div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className={this.props.addInsurance.PlanCode.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <Field name="PlanCode" component={renderField} label={languagecontent['plancode']} maxLength={10} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className={this.props.isListLoading ? "form-group form-focus focused" : this.props.addInsurance.ExpiryDate ? "form-group form-focus focused" : "form-group form-focus"}>
                                                <div className={languagecontent['expirydate'] == undefined ? "cal-icon hide" : "cal-icon"}>
                                                    <Field name="ExpiryDate" component={renderExpDob} label={languagecontent['expirydate'] == undefined ? "" : languagecontent['expirydate'] + " " + "(" + dateformat + ")"} maxLength={15} type="text" loading={this.props.isListLoading} />
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="card" style={{ marginLeft: "25px", marginRight: "25px" }} >
                                    <div className="mt-3 mr-3 row">
                                        <div className="mob-col1 col-md-7 col-lg-7"><h5 className="ml-4">{languagecontent['document']}</h5></div>
                                        <div className="mob-col2 col-md-5 col-lg-5 text-right"><button type="button" className="btn btn-primary submit-btn" data-toggle="modal" data-target="#myModal">{languagecontent['documentaddbutton']}</button></div>
                                    </div>
                                    <DocumentList />

                                </div>

                            </div>



                            <div className="col-sm-12">
                                {
                                    _array[1] != "addinsurance" ?
                                        <div className="form-group form-focus">
                                            <button className={"btn btn-lg btn-primary float-left mr-2"} disabled={this.props.isPostLoading} type="submit">{this.props.isPostLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['submitbutton']}</button>
                                            <button className={"btn btn-lg btn-secondary float-left"} type="button" onClick={() => this.goBack()} >{languagecontent['backbutton']}</button>
                                        </div> : ""
                                }
                                {
                                    _array[1] == "addinsurance" ?
                                        <div className="form-group form-focus submit-section ">
                                            <button className={"btn submit-btn btn-primary float-right"} disabled={this.props.isPostLoading} type="submit">{this.props.isPostLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['proceedbutton']}</button>
                                        </div> : ""
                                }
                            </div>

                        </form>
                        <DocumentUpload />
                    </React.Fragment>}
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = ({ ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ProfileStore.actionCreators, ...CategoryStore.actionCreators, ...InsuranceAutoCompleStore.actionCreators, ...documentStore.actionCreators, ...AddInsuranceStore.actionCreators, ...documentUploadStore.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators });
const mapStateToProps = (state: ApplicationState) => {
    localStorage.setItem("InsuredDOB", (_insurance.InsuredDOB == null) || (_insurance.InsuredDOB == "") ? "" : _insurance.InsuredDOB)
    localStorage.setItem("ExpiryDate", (_insurance.ExpiryDate == null) || (_insurance.ExpiryDate == "") ? "" : _insurance.ExpiryDate)
    let values = {
        initialValues: _insurance,
        ...state.dashboardNav, ...state.login, ...state.profile, ...state.category, ...state.insuranceAutoCompletes, ...state.documentlist, ...state.addInsurance, ...state.documentupload, ...state.account, ...state.langResources
    };
    return values;

}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'dformInsurance',
    enableReinitialize: true,
})(InsurenceForm as any))
