import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import * as ProfileStore from '../../store/patient/ProfileSettings';
import * as LoginStore from '../../store/account/Login';
import * as HandleRequest from '../../helper/Request';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import ContactForm from '../widget/ContactForm';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { floatingJS, groupArrayOfObjects, seo } from '../../helper/Script';
import * as VisitDetail from '../../store/appointment/VisitDetail';
import * as PrintDetail from '../../store/appointment/Prescription';
import { stat } from 'fs';
import ContentLoader from 'react-content-loader';
import { dateUTCFormat, dateFormat, statusColor, dateTimeFormat, paymentstatusColor, setTittle } from '../../helper/formvalidation';
import Moment from 'moment';
import * as TransactionStore from '../../store/patient/Transaction';
import * as SiteDetailStore from '../../store/SiteDetail';
import * as Account from '../../store/siteaccount/Account';
import * as  documentStore from '../../store/library/document/DocumentList';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as EncounterModuleStore from '../../store/appointment/encounterModule';
import DocumentUpload from '../library/document/DocumentUpload';
import DocumentList from '../library/document/DocumentList';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as CancelAppointmentStore from '../../store/appointment/CancelAppointmentModel';
import CancelAppointmentModel from './CancelAppointmentModel';
import { getDiscountPrice } from '../Patient/Booking';
import { jsPDF } from 'jspdf';
type ProfileProps =
    ProfileStore.ProfileState & LoginStore.LoginState & HandleRequest.Request & TransactionStore.TransactionDetailState & SiteDetailStore.SiteDetailState & EncounterModuleStore.EncounterModuleState & Account.AccountState & LanguageResourcesStore.LanguageResouresState & typeof ProfileStore.actionCreators & VisitDetail.VisitDetailState & Account.AccountState & documentStore.DocumentListsState & CancelAppointmentStore.CancelAppointmentState & PrintDetail.PrintDetailState & typeof VisitDetail.actionCreators & typeof PrintDetail.actionCreators &
    typeof TransactionStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof documentStore.actionCreators & typeof EncounterModuleStore.actionCreators & typeof Account.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof CancelAppointmentStore.actionCreators & RouteComponentProps<{}>;
var locationid = "";
var contact: ProfileStore.Contact;
var vitaltakenon: any = [];
var __currency: any;
var Patientname: any;
var languagecontent: any;
var cancelduration: any = "";
var enableappcancel: any = "";
var langdata: any = "";
declare var bootstrap: any;
class VisitDetailView extends React.PureComponent<ProfileProps> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        //seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
        this.ensureDataFetched();
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        floatingJS();
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }

    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    private ensureDataFetched() {
        //this.props.getSignalRCredential();
        this.props.getLangResources();
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        Patientname = patient.name;
        const moduleName = "Patient";
        const memberId = patient.memberId;
        this.props.requestVisitDetail(this.props.match.params);
        this.props.requestDocumentLists(memberId, moduleName);
        var data = window.location.pathname;
        //console.log(data, "data")

    }
    public render() {
        return (
            <React.Fragment>
                {this.renderContact()}
            </React.Fragment>
        );
    }
    handleRedirect(providerdetail: any) {
        this.props.redirectdrProfile(providerdetail);
    }
    onDownload(prescriptionid: any) {
        var layout: any = "";
        layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
        if (layout == undefined)
            layout = "";
        else
            layout = layout.Value;
        if (layout == "NavSanthigram") {
            var prescriptionprint: any = localStorage.getItem('prescriptionprint');
            if (prescriptionprint != "null" && prescriptionprint != null) {
                var docinnerhtml: any = document.getElementById('dMedicationPrint');
                var w: any = window.open();
                w.document.write(docinnerhtml.innerHTML);
                w.print();
                w.close();
            }
        }
        else {
            this.props.requestPrintDetail(prescriptionid);
        }
    }
    onDownloadPaymentPrint(Id: any, discount: any) {

        this.props.requestPrintPaymentDetail(Id, discount);

    }
    onpurchaseUrl(url: any,) {

        window.open(url, '_blank');
        //const a = document.createElement('a');
        //a.href = url;
        //a.target = '_blank';
        //a.rel = 'noopener noreferrer';
        //a.click();

    }
    cancelAppointment = (value: any) => {
        var myModal = new bootstrap.Modal(document.getElementById('dCancelModal'), {
            backdrop: 'static',
            keyboard: false
        })
        myModal.show();
        this.props.setCancelAppointment(value);
    }

    checkRow(Id: any) {
        //console.log(this.props.medication[0].prescriptionId, Id)
        return this.props.medication[0].prescriptionId == Id

    }
    public renderContact() {
        var dataloc = (window.location.pathname).split("/");
        //console.log(dataloc, "dataloc")
        var humurl = this.props.appointmentDetail.hummyURL;
        let groupmedication = groupArrayOfObjects(this.props.medication, 'prescriptionId');
        var medicationprint = this.props.medication;
        if (medicationprint.length > 0) {
            var prescriptionid = medicationprint[0].prescriptionId;
            this.props.getprescriptionprint(prescriptionid);
        }
        //console.log(groupmedication);
        //const currency = this.props.Settings.filter(function (value: any) {
        //    return value.name === "Currency"
        //});
        //currency.forEach((value: any) => {
        //    var displayCurrencycode = value.displayCode;
        //    localStorage.setItem("currency", displayCurrencycode);
        //})
        var _currency: any = "";

        __currency = this.props.Settings.filter((s: any) => s.name == "Currency");

        var value: any;
        if (__currency.length == 0) {
            _currency = "";
        }
        else {
            if (__currency[0].value == "") {
                _currency = __currency[0].displayCode;
                localStorage.setItem("currency", _currency);
            }
            else {
                _currency = __currency[0].customizeValue;
                localStorage.setItem("currency", _currency);
            }


        }
        var currency: any = _currency;
        const emptyFilter = this.props.PatientVitals.filter(function (value: any) {
            return (value.value !== "")
        });
        const discountfilter = this.props.Settings.filter(function (value: any) {
            return (value.name == "Client Discount Code")
        });
        const discount = this.props.Settings.filter((ca: any) => ca.name == "Client Discount Code");
        if (discount.length != 0) {
            const myArray = discount[0].customizeValue.split("|");
            var discountcode = myArray[0];
            var discountnumber = myArray[1];
            var discounttext = myArray[2];
        }
        var discountval = "";
        if (discountfilter.length > 0) {
            //console.log(discountfilter[0].customizeValue, "discountfilter")
            discountval = discountfilter[0].customizeValue;
        }

        var sorted_meetings = emptyFilter.sort((a, b) => {
            return new Date(a.takenOn).getTime() -
                new Date(b.takenOn).getTime()
        }).reverse();

        var data = groupBy(sorted_meetings, "takenOn");
        var orderedDates: any = {};
        Object.keys(data).sort(function (a: any, b: any) {
            return Date.parse(b) - Date.parse(a);
        }).forEach(function (key: any) {
            orderedDates[key] = data[key];
        })
        var vitaldata: any = [];
        var hightfilter: any = [];
        var weightfilter: any = [];
        var bmifilter: any = [];
        var bpsisfilter: any = [];
        var bpdiafilter: any = [];
        var otherfilter: any = [];

        vitaldata = { vital: Object.values(orderedDates)[0] == undefined ? [] : Object.values(orderedDates)[0] };
        if (vitaldata.length != 0) {

            hightfilter = vitaldata.vital.filter(function (value: any) {
                return (value.vital.name === "Height")
            });
            weightfilter = vitaldata.vital.filter(function (value: any) {
                return (value.vital.name === "Weight")
            });
            bmifilter = vitaldata.vital.filter(function (value: any) {
                return (value.vital.name === "BMI")
            });
            bpsisfilter = vitaldata.vital.filter(function (value: any) {
                return (value.vital.code === "SYB")
            });
            bpdiafilter = vitaldata.vital.filter(function (value: any) {
                return (value.vital.code === "DIB")
            });
            otherfilter = vitaldata.vital.filter(function (value: any) {
                return (value.vital.code !== "Ht" && value.vital.code !== "Wt" && value.vital.name != "Height" && value.vital.code !== "SYB" && value.vital.code !== "DIB")
            });
        }
        var isVitalLength: any = "";
        var IsVital: any = "";
        var IsNote: any = "";
        var IsCI: any = "";
        var isNoteLength: any = "";
        var isLab: any = "";
        var isLabLength: any = "";
        var isRadiology: any = "";
        var isRadiologyLength: any = "";
        var isAllergy: any = "";
        var isAllergyLength: any = "";
        var isCILength: any = "";
        var isMedicationLength: any = "";
        var isMedication: any = "";
        isVitalLength = this.props.encounterModules.filter((em: any) => em.ModuleId == "1")[0];
        isNoteLength = this.props.encounterModules.filter((em: any) => em.ModuleId == "4")[0];
        isLabLength = this.props.encounterModules.filter((em: any) => em.ModuleId == "11")[0];
        isRadiologyLength = this.props.encounterModules.filter((em: any) => em.ModuleId == "22")[0];
        isAllergyLength = this.props.encounterModules.filter((em: any) => em.ModuleId == "8")[0];
        isCILength = this.props.encounterModules.filter((em: any) => em.ModuleId == "55")[0];
        isMedicationLength = this.props.encounterModules.filter((em: any) => em.ModuleId == "15")[0];
        if (isVitalLength != undefined) {
            IsVital = isVitalLength;
        } else {
            IsVital = 0;
        }
        if (isNoteLength != undefined) {
            IsNote = isNoteLength;
        } else {
            IsNote = 0;
        }
        if (isLabLength != undefined) {
            isLab = isLabLength;
        } else {
            isLab = 0;
        } if (isMedicationLength != undefined) {
            isMedication = isMedicationLength;
        } else {
            isMedication = 0;
        }
        if (isRadiologyLength != undefined) {
            isRadiology = isRadiologyLength;
        } else {
            isRadiology = 0;
        }
        if (isAllergyLength != undefined) {
            isAllergy = isAllergyLength;
        } else {
            isAllergy = 0;
        }
        if (isCILength != undefined) {
            IsCI = isCILength;
        } else {
            IsCI = 0;
        }
        var enableJampstart: any = this.props.Settings.filter((se: any) => se.name == "DoseSpotJumpStart")[0];
        if (enableJampstart != undefined) {
            enableJampstart = enableJampstart.customizeValue
        }
        var prescriptiontype: any = this.props.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
        if (prescriptiontype != undefined) {
            prescriptiontype = prescriptiontype.customizeValue
        }

        //console.log(prescriptiontype)

        cancelduration = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "cancel duration prior to appointment")[0];
        if (cancelduration != undefined) {
            cancelduration = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "cancel duration prior to appointment")[0].customizeValue;
        }
        enableappcancel = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "appointment cancel by patient")[0];
        if (enableappcancel != undefined) {
            enableappcancel = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "appointment cancel by patient")[0].customizeValue;
        }
        var _resultbmi: any = hightfilter.length != 0 && weightfilter.length != 0 ? true : false;
        var enablefamilymember: any = this.props.Settings.filter((s: any) => s.name == "Enable Family Member");
        if (enablefamilymember.length == 0)
            enablefamilymember = null
        else
            enablefamilymember = enablefamilymember[0].customizeValue

        languagecontent = this.props.LanguageResoures;

        var customform = groupBy(this.props.CustomForms, "formClientTitle");
        var patientId: any;
        if (patientId != null || patientId != undefined) {
            return (this.props.paymentDetail.map)
        }

       //console.log(this.props.providerDetail);

        return (
            <React.Fragment>
                <div className="content details">
                    <div className="container-fluid">
                        <div className="row">
                            <DashboardNavMenu />
                            <div className="col-md-7 col-lg-8 col-xl-9 /*hidden-onpage-load*/">
                                <div className="card Detail">
                                    <div className="card-body">
                                        {
                                            this.props.visitDetailLoading == true ?
                                                <React.Fragment>
                                                    {window.innerWidth <= 760 ?
                                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        >
                                                            <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                            <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                            <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                            viewBox="0 0 380 70">
                                                            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                            <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                        </ContentLoader>
                                                    }
                                                </React.Fragment>
                                                :
                                             this.props.providerDetail ?
                                                        <div className="doctor-widget row">
                                                            <div className="col-12 col-lg-8 col-sm-12 col-md-12">
                                                                <div className=" doc-info-left">
                                                                    <div className="doctor-img">
                                                                        {
                                                                            this.props.providerDetail.photo != null ? <img src={"patient/ImageUrl/member/" + this.props.providerDetail.doctorId} className="img-fluid" alt="User Image" />
                                                                                : <img src="client/default/img/blankimg.jpg" className="img-fluid" alt="User Image" />
                                                                        }

                                                                    </div>
                                                                    <div className="doc-info-cont doc-info-ipad">
                                                                        <h4 className="doc-name">{this.props.providerDetail.doctorName == "Not Assigned" ? languagecontent['notassigned'] : this.props.providerDetail.doctorName}</h4>
                                                                    {
                                                                        this.props.providerDetail.doctorId != "00000000-0000-0000-0000-000000000000"
                                                                            ? <p className="doc-speciality">
                                                                                {this.props.providerDetail.speciality.length != 0 ?
                                                                                    <p className="doc-speciality spe_text_elips">{this.props.providerDetail.speciality.map((spec: VisitDetail.Speciality) =>
                                                                                        spec.name
                                                                                    ).join(', ')}
                                                                                    </p> : ' '
                                                                                }
                                                                            </p>:""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-4 col-sm-12  col-md-12">
                                                                {/*<div className="clini-infos">*/}
                                                                {/*    {this.props.encounterDetail ?*/}
                                                                {/*        this.props.encounterDetail.statusId != 1 ?*/}
                                                                {/*            <ul>*/}
                                                                {/*                <p>{this.props.encounterDetail ? this.props.encounterDetail.encounterTypeName : ""}  </p>*/}

                                                                {/*                {*/}
                                                                {/*                    this.props.appointmentDetail.appointmentOn != "" && this.props.appointmentDetail.appointmentOn != null ?*/}
                                                                {/*                        <p><i className="fa fa-calendar" style={{ color: "#272b41" }} aria-hidden="true"></i> {dateFormat(this.props.appointmentDetail.appointmentOn)} {Moment(this.props.appointmentDetail.appointmentOn).format('LT')}</p>*/}
                                                                {/*                        :*/}
                                                                {/*                        <p><i className="fa fa-calendar" aria-hidden="true"></i>     {this.props.encounterDetail ? dateFormat(this.props.encounterDetail.encounterOn) : ""} {this.props.encounterDetail ? Moment(this.props.encounterDetail.encounterOn).format('LT') : ""}</p>*/}
                                                                {/*                }*/}


                                                                {/*                <p><span className={statusColor(this.props.encounterDetail.statusId)}>{this.props.encounterDetail.statusName}</span></p>*/}
                                                                {/*            </ul>*/}
                                                                {/*            : "" : ""}*/}
                                                                {/*</div>*/}
                                                                <div className=" doc-info-right deail-doc-btn">
                                                                    <div className="clinic-booking">
                                                                        {/*{*/}
                                                                        {/*    enableappcancel == "Yes" ?*/}
                                                                        {/*        <React.Fragment>*/}
                                                                        {/*            {*/}
                                                                        {/*                this.props.encounterDetail.statusName == "Scheduled" ?*/}
                                                                        {/*                    <React.Fragment>*/}
                                                                        {/*                        <a target="_blank" className="btn btn-sm bg-primary-light" onClick={() => this.cancelAppointment(this.props.appointmentDetail)}>Cancel</a>*/}
                                                                        {/*                    </React.Fragment> : ""*/}
                                                                        {/*            }*/}
                                                                        {/*        </React.Fragment> : ""*/}
                                                                        {/*}*/}
                                                                        {enablefamilymember == "Yes" ?
                                                                            <p className="spe_text_elips"> <span>{this.props.appointmentDetail == undefined ? "" : languagecontent['consultationfor']}</span> <span className="doc-speciality">{this.props.appointmentDetail.consultantName == "" ? Patientname : this.props.appointmentDetail.consultantName}</span></p>:""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ""               
                                        }
                                                            
                                    </div>
                                </div>



                                {
                                    this.props.visitDetailLoading == true ?
                                        <div className="card Detail">
                                            <div className="card-body">
                                                <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                    viewBox="0 0 400 160" height={160} width={400}>
                                                    <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                                                    <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                                                    <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                                                    <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                                                    <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                                                    <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
                                                </ContentLoader>
                                            </div></div>
                                        :
                                        this.props.appointmentDetail.appointmentId ?
                                            <React.Fragment>
                                                <div className="card Detail">
                                                    <div className="card-body" style={{ paddingBottom: "3px" }}>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h4 className="text-left"><span className="sectionTitleText">{languagecontent['appointmentinformation']}</span></h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-lg-8 col-sm-12  col-md-12">
                                                                <p> {this.props.appointmentDetail.reason} ({this.props.appointmentDetail.referenceNo}) <span className={statusColor(this.props.appointmentDetail.statusId)}>{this.props.appointmentDetail.statusName}</span>

                                                                    {
                                                                    this.props.appointmentDetail.rescheduleRefId == null ? "" :
                                                                        <span className="text-info">{languagecontent['rescheduled'] == undefined ? "Rescheduled" : languagecontent['rescheduled']}</span>
                                                                    }
                                                                </p>
                                                                {
                                                                    this.props.encounterDetail.note == "" ? "" : <p>{languagecontent['note']} : {this.props.encounterDetail.note}</p>
                                                                }
                                                                {
                                                                    this.props.appointmentDetail.appointmentOn != "" && this.props.appointmentDetail.appointmentOn != null ?
                                                                        <p><i className="fa fa-calendar" style={{ color: "#272b41" }} aria-hidden="true"></i> {dateFormat(this.props.appointmentDetail.appointmentOn)} {Moment(this.props.appointmentDetail.appointmentOn).format('LT')}</p>
                                                                        :
                                                                        <p><i className="fa fa-calendar" aria-hidden="true"></i>     {this.props.encounterDetail ? dateFormat(this.props.encounterDetail.encounterOn) : ""} {this.props.encounterDetail ? Moment(this.props.encounterDetail.encounterOn).format('LT') : ""}</p>
                                                                }
                                                                {/*<p><i className="fa fa-calendar" style={{ color: "#272b41" }} aria-hidden="true"></i> {dateFormat(this.props.appointmentDetail.appointmentOn)} {Moment(this.props.appointmentDetail.appointmentOn).format('LT')}</p>*/}

                                                                <p><i className={this.props.appointmentDetail.appointmentType == languagecontent['inperson'] ? "fas fa-user-circle" : this.props.appointmentDetail.appointmentType == languagecontent['teleconsultation'] ? "fas fa-video" : "fas fa-phone fa-flip-horizontal"} style={{ color: "#4e4852" }}></i> {this.props.appointmentDetail.appointmentType}</p>




                                                            </div>
                                                            <div className="col-12 col-lg-4 col-sm-12  col-md-12">
                                                                <div className=" doc-info-right deail-doc-btn jbtn">
                                                                    <React.Fragment>
                                                                        {

                                                                            (this.props.encounterDetail.statusId === 1 || this.props.encounterDetail.statusId === 7) && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                <a href={this.props.appointmentDetail.hummyURL} target="_blank" className="btn btn-md btn-primary">
                                                                                    {languagecontent['joincall']}
                                                                                </a>
                                                                            </div> : ""
                                                                        }
                                                                    </React.Fragment>
                                                                    {
                                                                        languagecontent['inprogress'] != undefined ?
                                                                            <React.Fragment>
                                                                                {

                                                                                    this.props.encounterDetail.statusName.toLowerCase() === languagecontent['inprogress'].replace(/\s/g, '').toLowerCase() && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                        <a href={this.props.appointmentDetail.hummyURL} target="_blank" className="btn btn-md btn-primary">
                                                                                            {languagecontent['joincall']}
                                                                                        </a>
                                                                                    </div> : ""
                                                                                }

                                                                                {

                                                                                    this.props.encounterDetail.statusName.toLowerCase() === languagecontent['inprogress'].toLowerCase() && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                        <a href={this.props.appointmentDetail.hummyURL} target="_blank" className="btn btn-md btn-primary">
                                                                                            {languagecontent['joincall']}
                                                                                        </a>
                                                                                    </div> : ""
                                                                                }

                                                                            </React.Fragment> : ""
                                                                    }
                                                                    {
                                                                        languagecontent['checkin'] != undefined ?
                                                                            <React.Fragment>
                                                                                {

                                                                                    this.props.encounterDetail.statusName.toLowerCase() === languagecontent['checkin'].replace(/\s/g, '').toLowerCase() && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                        <a href={this.props.appointmentDetail.hummyURL} target="_blank" className="btn btn-md btn-primary">
                                                                                            {languagecontent['joincall']}
                                                                                        </a>
                                                                                    </div> : ""
                                                                                }
                                                                            </React.Fragment> : ""
                                                                    }

                                                                    <React.Fragment>
                                                                        {

                                                                            this.props.encounterDetail.statusId === 6 ? <div className="clinic-booking">
                                                                                <React.Fragment>
                                                                                    <h6 className="text-muted">{languagecontent['reasonforcancellation']}</h6>
                                                                                    <div>{this.props.encounterDetail.statusId === 6 && this.props.encounterDetail.oldProviderId != null ? languagecontent['prvdrunassgnd'] : this.props.appointmentDetail.cancelReason}</div>
                                                                                </React.Fragment>

                                                                            </div> : ""
                                                                        }
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            :

                                            this.props.encounterDetail ?
                                                <React.Fragment>
                                                    <div className="card Detail">
                                                        <div className="card-body" style={{ paddingBottom: "3px" }}>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <h4 className="text-left"><span className="sectionTitleText">Consultation information</span></h4>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 col-lg-8 col-sm-12  col-md-12">
                                                                    <p> {this.props.encounterDetail.reason} ({this.props.encounterDetail.encounterNo}) <span className={statusColor(this.props.encounterDetail.statusId)}>{this.props.encounterDetail.statusName}</span></p>

                                                                    <p> <i className="fa fa-calendar" style={{ color: "#272b41" }} aria-hidden="true"></i> {dateFormat(this.props.encounterDetail.encounterOn)} {Moment(this.props.encounterDetail.encounterOn).format('LT')}</p>

                                                                    <p><i className={this.props.encounterDetail.encounterTypeName == languagecontent['inperson'] ? "fas fa-user-circle" : "fas fa-video"} style={{ color: "#4e4852" }}></i> {this.props.encounterDetail.encounterTypeMode}</p>

                                                                </div>
                                                                <div className="col-12 col-lg-4 col-sm-12  col-md-12">
                                                                    <div className=" doc-info-right deail-doc-btn jbtn">
                                                                        <React.Fragment>
                                                                            {

                                                                                this.props.encounterDetail.statusId === 1 && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                    <a href={humurl} target="_blank" className="btn btn-md btn-primary">
                                                                                        {languagecontent['joincall']}
                                                                                    </a>
                                                                                </div> : ""
                                                                            }
                                                                        </React.Fragment>
                                                                        <React.Fragment>
                                                                            {

                                                                                this.props.encounterDetail.statusName.toLowerCase() === languagecontent['inprogress'].replace(/\s/g, '').toLowerCase() && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                    <a href={humurl} target="_blank" className="btn btn-md btn-primary">
                                                                                        {languagecontent['joincall']}
                                                                                    </a>
                                                                                </div> : ""
                                                                            }
                                                                        </React.Fragment>
                                                                        <React.Fragment>
                                                                            {

                                                                                this.props.encounterDetail.statusName.toLowerCase() === languagecontent['checkin'].replace(/\s/g, '').toLowerCase() && this.props.encounterDetail.encounterTypeMode == languagecontent['teleconsultation'] ? <div>
                                                                                    <a href={humurl} target="_blank" className="btn btn-md btn-primary">
                                                                                        {languagecontent['joincall']}
                                                                                    </a>
                                                                                </div> : ""
                                                                            }
                                                                        </React.Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>

                                                : ""
                                }

                                {
                                    isMedication != 0 ?
                                        <div>
                                            {
                                                this.props.medication.length > 0 ?
                                                    <div className="card Detail">
                                                        <div className="card-body">
                                                            <div>
                                                                <div className="row sectionTitle">
                                                                    <div className="col-6 col-xs-9 col-md-6 marginAuto">
                                                                        <h4 className="text-left"><span className="sectionTitleText">{isMedication.ClientTitle}</span></h4> </div>
                                                                    <div className="col-6 col-xs-3 col-md-6 marginAuto text-right"></div>
                                                                </div>
                                                                {
                                                                    Object.keys(groupmedication).map((item, index) =>
                                                                        <React.Fragment>
                                                                            {
                                                                                prescriptiontype == "Both" ?
                                                                                    <div className="card Detail">
                                                                                        {
                                                                                            groupmedication[item].length > 1 ?

                                                                                                <div className="card-body">

                                                                                                    {
                                                                                                        groupmedication[item].map((medication: VisitDetail.MedicationDetail, index: number) =>
                                                                                                            <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                                                <div className="col-12 col-lg-4 col-xs-4 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['medicine']}</b></div>
                                                                                                                    <div className="tbldesc">
                                                                                                                        {medication.drug.name != null && medication.drug.name != "" ? <p><span className="doc-speciality">{languagecontent['drug']}: </span><span>{medication.drug.name}</span></p> : ""}
                                                                                                                        {medication.prescribedOn != null && medication.prescribedOn != "" ? <p><span className="doc-speciality">{languagecontent['prescribedon']}: </span><span>{dateFormat(medication.prescribedOn)} {Moment(medication.prescribedOn).format('LT')}</span></p> : ""}
                                                                                                                        {medication.user.fullName != null && medication.user.fullName != "" ? <p><span className="doc-speciality">{languagecontent['prescribedby']}: </span><span>{medication.user.fullName}</span></p> : ""}
                                                                                                                        {medication.purchaseUrl != null && medication.purchaseUrl != "" ? <p><span className="doc-speciality">{languagecontent['clickhereto']}: </span><h3 className='d-inline' onClick={() => this.onpurchaseUrl(medication.purchaseUrl)} style={{ fontSize: '13px', cursor: 'pointer' }}>{languagecontent['purchaseproduct']}</h3></p> : ""}
                                                                                                                        {medication.note != null && medication.note != "" ? <p><span className="doc-speciality">{languagecontent['note']}: </span><span >{medication.note}</span></p> : ""}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['quantity']}</b></div> <div className="tbldesc">{medication.quantity != null ? medication.quantity : "-"} </div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['frequency']}</b></div> <div className="tbldesc">{medication.sIG != null ? medication.sIG : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['dayssupply']}</b></div><div className="tbldesc">{medication.days != null ? medication.days : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerowLast"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}></div><div className="tbldesc"> {medication.prescription.statusId == 0 ? "" : medication.prescription.statusId >= 1 && index == 0 ? <button onClick={() => this.onDownload(medication.prescription.id)} hidden={this.props.encounterDetail.statusId != 8 || medication.prescription.extRefId !== ''} className='btn btn-sm bg-primary-light' ><i className='fas fa-print'>&nbsp;</i>{languagecontent['print']}</button> : ""}</div></div>

                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>

                                                                                                :

                                                                                                <div className="card-body">

                                                                                                    {
                                                                                                        groupmedication[item].map((medication: VisitDetail.MedicationDetail, index: number) =>
                                                                                                            <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                                                <div className={"col-12 col-lg-3 col-xs-4 col-md-12 mblerow"}> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['medicine']} </b></div>
                                                                                                                    <div className="tbldesc">
                                                                                                                        {medication.drug.name != null && medication.drug.name != "" ? <p><span className="doc-speciality">{languagecontent['drug']}: </span><span>{medication.drug.name} </span></p> : ""}
                                                                                                                        {medication.startedOn != null && medication.startedOn != "" ? <p><span className="doc-speciality">{languagecontent['effectivedate']}: </span><span >{dateFormat(medication.startedOn)}</span></p> : ""}
                                                                                                                        {medication.direction != null && medication.direction != "" ? <p><span className="doc-speciality">{languagecontent['direction']}: </span><span >{medication.direction}</span></p> : ""}
                                                                                                                        <span hidden={enableJampstart == "Yes" ? false : true}>
                                                                                                                            <p hidden={medication.pharmacyName != "Master Pharmacy" ? false : true}><span className="doc-speciality">{languagecontent['pharmacy']}: </span> {medication.pharmacyName}</p>
                                                                                                                        </span>
                                                                                                                        {medication.prescribedOn != null && medication.prescribedOn != "" ? <p><span className="doc-speciality">{languagecontent['prescribedon']}: </span><span>{dateFormat(medication.prescribedOn)} {Moment(medication.prescribedOn).format('LT')}</span></p> : ""}
                                                                                                                        {medication.user.fullName != null && medication.user.fullName != "" ? <p><span className="doc-speciality">{languagecontent['prescribedby']}: </span><span>{medication.user.fullName}</span></p> : ""}
                                                                                                                        {medication.purchaseUrl != null && medication.purchaseUrl != "" ? <p><span className="doc-speciality">{languagecontent['clickhereto']}: </span><h3 className='d-inline' onClick={() => this.onpurchaseUrl(medication.purchaseUrl)} style={{ fontSize: '13px', cursor: 'pointer' }}>{languagecontent['purchaseproduct']}</h3></p> : ""}
                                                                                                                        {medication.note != null && medication.note != "" ? <p><span className="doc-speciality">{languagecontent['note']}: </span><span >{medication.note}</span></p> : ""}

                                                                                                                        {/*<span hidden={enableJampstart == "Yes" ? false : true}>*/}
                                                                                                                        {/*    <p hidden={medication.pharmacyName != "Master Pharmacy" ? false : true}>{medication.prescription.statusId != null ? <p><span className="doc-speciality">{languagecontent['status']}: </span><span >{medication.prescription.statusId == 1 ? "Entered" : medication.prescription.statusId == 2 ? "Printed " : medication.prescription.statusId == 3 ? "Sending " : medication.prescription.statusId == 4 ? "eRxSent " : medication.prescription.statusId == 5 ? "FaxSent " : medication.prescription.statusId == 6 ? "Error" : medication.prescription.statusId == 7 ? "Deleted" : medication.prescription.statusId == 8 ? "Requested" : medication.prescription.statusId == 9 ? " Edited" : medication.prescription.statusId == 10 ? " EpcsError" : ""}</span></p> : ""}</p>*/}
                                                                                                                        {/*</span>*/}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblvisit hideTitle tblhead"}><b>{languagecontent['quantity']}</b></div> <div className="tbldesc">{medication.quantity != null ? medication.quantity : "-"} </div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['frequency']}</b></div> <div className="tbldesc">{medication.sIG != "" ? medication.sIG : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['dayssupply']}</b></div><div className="tbldesc">{medication.days != null ? medication.days : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-1 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>Refill</b></div><div className="tbldesc">{medication.refill != null ? medication.refill : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerowLast"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}></div><div className="tbldesc"> {medication.prescription.statusId == 0 ? "" : medication.prescription.statusId >= 1 && index == 0 ? <button hidden={this.props.encounterDetail.statusId != 8 || medication.prescription.extRefId !== ''} onClick={() => this.onDownload(medication.prescription.id)} className='btn btn-sm bg-primary-light'><i className='fas fa-print'></i>&nbsp;{languagecontent['print']}</button> : ""}</div></div>

                                                                                                            </div>

                                                                                                        )
                                                                                                    }
                                                                                                </div>

                                                                                        }
                                                                                    </div>

                                                                                    :

                                                                                    prescriptiontype == "DoseSpot" ?
                                                                                        <div className="card Detail">
                                                                                            {
                                                                                                <div className="card-body">

                                                                                                    {
                                                                                                        groupmedication[item].map((medication: VisitDetail.MedicationDetail, index: number) =>
                                                                                                            <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                                                <div className={enableJampstart == "Yes" ? "col-12 col-lg-4 col-xs-6 col-md-12 mblerow" : "col-12 col-lg-4 col-xs-4 col-md-12 mblerow"}> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['medicine']} </b></div> <div className="tbldesc">
                                                                                                                    {medication.drug.name != null && medication.drug.name != "" ? <p><span className="doc-speciality">{languagecontent['drug']}: </span><span>{medication.drug.name} </span></p> : ""}
                                                                                                                    {medication.startedOn != null && medication.startedOn != "" ? <p><span className="doc-speciality">{languagecontent['effectivedate']}: </span><span >{dateFormat(medication.startedOn)}</span></p> : ""}
                                                                                                                    {medication.direction != null && medication.direction != "" ? <p><span className="doc-speciality">{languagecontent['direction']}: </span><span >{medication.direction}</span></p> : ""}
                                                                                                                    <span hidden={enableJampstart == "Yes" ? false : true}>
                                                                                                                        <p><span className="doc-speciality">Pharmacy: </span> {medication.pharmacyName}</p>
                                                                                                                    </span>
                                                                                                                    {medication.prescribedOn != null && medication.prescribedOn != "" ? <p><span className="doc-speciality">{languagecontent['prescribedon']}: </span><span>{dateFormat(medication.prescribedOn)} {Moment(medication.prescribedOn).format('LT')}</span></p> : ""}
                                                                                                                    {medication.user.fullName != null && medication.user.fullName != "" ? <p><span className="doc-speciality">{languagecontent['prescribedby']}: </span><span>{medication.user.fullName}</span></p> : ""}
                                                                                                                    {medication.purchaseUrl != null && medication.purchaseUrl != "" ? <p><span className="doc-speciality">{languagecontent['clickhereto']}: </span><h3 className='d-inline' onClick={() => this.onpurchaseUrl(medication.purchaseUrl)} style={{ fontSize: '13px', cursor: 'pointer' }}>{languagecontent['purchaseproduct']}</h3></p> : ""}
                                                                                                                    {medication.note != null && medication.note != "" ? <p><span className="doc-speciality">{languagecontent['note']}: </span><span >{medication.note}</span></p> : ""}
                                                                                                                    {/*<span hidden={enableJampstart == "Yes" ? false : true}>*/}
                                                                                                                    {/*    {medication.prescription.statusId != null ? <p><span className="doc-speciality">Status: </span><span >{medication.prescription.statusId == 1 ? "Entered" : medication.prescription.statusId == 2 ? "Printed " : medication.prescription.statusId == 3 ? "Sending " : medication.prescription.statusId == 4 ? "eRxSent " : medication.prescription.statusId == 5 ? "FaxSent " : medication.prescription.statusId == 6 ? "Error" : medication.prescription.statusId == 7 ? "Deleted" : medication.prescription.statusId == 8 ? "Requested" : medication.prescription.statusId == 9 ? " Edited" : medication.prescription.statusId == 10 ? " EpcsError" : ""}</span></p> : ""}*/}
                                                                                                                    {/*</span>*/}
                                                                                                                </div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['quantity']}</b></div> <div className="tbldesc">{medication.quantity != null ? medication.quantity : "-"} </div></div>
                                                                                                                <div hidden={enableJampstart == "Yes"} className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['frequency']}</b></div> <div className="tbldesc">{medication.sIG != "" ? medication.sIG : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['dayssupply']}</b></div><div className="tbldesc">{medication.days != null ? medication.days : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow" hidden={enableJampstart == "No"}> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>Refill</b></div><div className="tbldesc">{medication.refill != null ? medication.refill : "-"}</div></div>
                                                                                                                <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerowLast" > <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}></div><div className="tbldesc" > {medication.prescription.statusId == 0 ? "" : medication.prescription.statusId >= 1 && index == 0 ? <button hidden={this.props.encounterDetail.statusId != 8 || medication.prescription.extRefId !== ''} onClick={() => this.onDownload(medication.prescription.id)} className='btn btn-sm bg-primary-light'><i className='fas fa-print'></i>&nbsp;{languagecontent['print']}</button> : ""}</div></div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div> :
                                                                                        <div className="card Detail">
                                                                                            {
                                                                                                groupmedication[item].length > 1 ?

                                                                                                    <div className="card-body">

                                                                                                        {
                                                                                                            groupmedication[item].map((medication: VisitDetail.MedicationDetail, index: number) =>
                                                                                                                <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                                                    <div className="col-12 col-lg-4 col-xs-4 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['medicine']}</b></div> <div className="tbldesc">
                                                                                                                        {medication.drug.name != null && medication.drug.name != "" ? <p><span className="doc-speciality">{languagecontent['drug']}: </span><span>{medication.drug.name}</span></p> : ""}
                                                                                                                        {medication.prescribedOn != null && medication.prescribedOn != "" ? <p><span className="doc-speciality">{languagecontent['prescribedon']}: </span><span>{dateFormat(medication.prescribedOn)} {Moment(medication.prescribedOn).format('LT')}</span></p> : ""}
                                                                                                                        {medication.user.fullName != null && medication.user.fullName != "" ? <p><span className="doc-speciality">{languagecontent['prescribedby']}: </span><span>{medication.user.fullName}</span></p> : ""}
                                                                                                                        {medication.purchaseUrl != null && medication.purchaseUrl != "" ? <p><span className="doc-speciality">{languagecontent['clickhereto']}: </span><h3 className='d-inline' onClick={() => this.onpurchaseUrl(medication.purchaseUrl)} style={{ fontSize: '13px', cursor: 'pointer' }}>{languagecontent['purchaseproduct']}</h3></p> : ""}
                                                                                                                        {medication.note != null && medication.note != "" ? <p><span className="doc-speciality">{languagecontent['note']}: </span><span >{medication.note}</span></p> : ""}
                                                                                                                    </div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['quantity']}</b></div> <div className="tbldesc">{medication.quantity != null ? medication.quantity : "-"} </div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['frequency']}</b></div> <div className="tbldesc">{medication.sIG != null ? medication.sIG : "-"}</div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['dayssupply']}</b></div><div className="tbldesc">{medication.days != null ? medication.days : "-"}</div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerowLast"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}></div><div className="tbldesc"> {medication.prescription.statusId == 0 ? "" : medication.prescription.statusId >= 1 && index == 0 ? <button onClick={() => this.onDownload(medication.prescription.id)} hidden={this.props.encounterDetail.statusId != 8 || medication.prescription.extRefId !== ''} className='btn btn-sm bg-primary-light' ><i className='fas fa-print'></i>&nbsp;{languagecontent['print']}</button> : ""}</div></div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }


                                                                                                    </div>

                                                                                                    :

                                                                                                    <div className="card-body">

                                                                                                        {
                                                                                                            groupmedication[item].map((medication: VisitDetail.MedicationDetail, index: number) =>
                                                                                                                <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                                                    <div className={enableJampstart == "Yes" ? "col-12 col-lg-4 col-xs-6 col-md-12 mblerow" : "col-12 col-lg-4 col-xs-4 col-md-12 mblerow"}> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['medicine']} </b></div> <div className="tbldesc">
                                                                                                                        {medication.drug.name != null && medication.drug.name != "" ? <p><span className="doc-speciality">{languagecontent['drug']}: </span><span>{medication.drug.name} </span></p> : ""}
                                                                                                                        {medication.startedOn != null && medication.startedOn != "" ? <p><span className="doc-speciality">{languagecontent['effectivedate']}: </span><span >{dateFormat(medication.startedOn)}</span></p> : ""}
                                                                                                                        {medication.direction != null && medication.direction != "" ? <p><span className="doc-speciality">{languagecontent['direction']}: </span><span >{medication.direction}</span></p> : ""}
                                                                                                                        <span hidden={enableJampstart == "Yes" ? false : true}>
                                                                                                                            <p><span className="doc-speciality">{languagecontent['pharmacy']}: </span> {medication.pharmacyName}</p>
                                                                                                                        </span>
                                                                                                                        {medication.prescribedOn != null && medication.prescribedOn != "" ? <p><span className="doc-speciality">{languagecontent['prescribedon']}: </span><span>{dateFormat(medication.prescribedOn)} {Moment(medication.prescribedOn).format('LT')}</span></p> : ""}
                                                                                                                        {medication.user.fullName != null && medication.user.fullName != "" ? <p><span className="doc-speciality">{languagecontent['prescribedby']}: </span><span>{medication.user.fullName}</span></p> : ""}
                                                                                                                        {medication.purchaseUrl != null && medication.purchaseUrl != "" ? <p><span className="doc-speciality">{languagecontent['clickhereto']}: </span><h3 className='d-inline' onClick={() => this.onpurchaseUrl(medication.purchaseUrl)} style={{ fontSize: '13px', cursor: 'pointer' }}>{languagecontent['purchaseproduct']}</h3></p> : ""}
                                                                                                                        {medication.note != null && medication.note != "" ? <p><span className="doc-speciality">{languagecontent['note']}: </span><span >{medication.note}</span></p> : ""}
                                                                                                                        {/*<span hidden={enableJampstart == "Yes" ? false : true}>*/}
                                                                                                                        {/*    {medication.prescription.statusId != null ? <p><span className="doc-speciality">Status: </span><span >{medication.prescription.statusId == 1 ? "Entered" : medication.prescription.statusId == 2 ? "Printed " : medication.prescription.statusId == 3 ? "Sending " : medication.prescription.statusId == 4 ? "eRxSent " : medication.prescription.statusId == 5 ? "FaxSent " : medication.prescription.statusId == 6 ? "Error" : medication.prescription.statusId == 7 ? "Deleted" : medication.prescription.statusId == 8 ? "Requested" : medication.prescription.statusId == 9 ? " Edited" : medication.prescription.statusId == 10 ? " EpcsError" : ""}</span></p> : ""}*/}
                                                                                                                        {/*</span>*/}
                                                                                                                    </div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['quantity']}</b></div> <div className="tbldesc">{medication.quantity != null ? medication.quantity : "-"} </div></div>
                                                                                                                    <div hidden={enableJampstart == "Yes"} className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['frequency']}</b></div> <div className="tbldesc">{medication.sIG != "" ? medication.sIG : "-"}</div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>{languagecontent['dayssupply']}</b></div><div className="tbldesc">{medication.days != null ? medication.days : "-"}</div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerow" hidden={enableJampstart == "No"}> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}><b>Refill</b></div><div className="tbldesc">{medication.refill != null ? medication.refill : "-"}</div></div>
                                                                                                                    <div className="col-12 col-lg-2 col-xs-2 col-md-12 mblerowLast"> <div className={index == 0 && this.checkRow(medication.prescriptionId) ? "tblhead tblvisit" : "tblhead hideTitle tblvisit"}></div><div className="tbldesc" > {medication.prescription.statusId == 0 ? "" : medication.prescription.statusId >= 1 && index == 0 ? <button hidden={this.props.encounterDetail.statusId != 8 || medication.prescription.extRefId !== ''} onClick={() => this.onDownload(medication.prescription.id)} className='btn btn-sm bg-primary-light'><i className='fas fa-print'></i>&nbsp;{languagecontent['print']}</button> : ""}</div></div>

                                                                                                                </div>
                                                                                                            )
                                                                                                        }


                                                                                                    </div>

                                                                                            }
                                                                                        </div>
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </div>
                                                            <div style={{ display: "none" }}>
                                                                <div id="dMedicationPrint" dangerouslySetInnerHTML={{ __html: this.props.Print }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                        </div>
                                        : ""}




                                {
                                    isAllergy != 0 ?
                                        <div className="card Detail">
                                            <div className="card-body">
                                                <div>
                                                    <div className="row sectionTitle">
                                                        <div className="col-6 col-xs-9 col-md-6 marginAuto">
                                                            <h4 className="text-left"><span className="sectionTitleText">{isAllergy.ClientTitle}</span></h4> </div>
                                                        <div className="col-6 col-xs-3 col-md-6 marginAuto text-right"></div>
                                                    </div>


                                                    <div className="card Detail">
                                                        {
                                                            this.props.PatientAllergy.length > 0 ?

                                                                <div className="card-body table table-borderless">

                                                                    {
                                                                        this.props.PatientAllergy.map((allergy: any, index: number) =>
                                                                            <div className={index > 0 ? "row detail-rows" : "row"}>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['allergytype']}</b></div> <div className="tbldesc">
                                                                                    {allergy.allergyTypeName != null && allergy.allergyTypeName != "" ? <p><span>{allergy.allergyTypeName}</span></p> : <span className="center">-</span>}
                                                                                </div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['allergyname']}</b></div> <div className="tbldesc">{allergy.name != null && allergy.name != "" ? allergy.name : <span className="center">-</span>} </div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['reaction']}</b></div> <div className="tbldesc">{allergy.reaction != null && allergy.reaction != "" ? allergy.reaction : <span className="center">-</span>}</div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['note']}</b></div><div className="tbldesc">{allergy.note != null && allergy.note != "" ? allergy.note : <span className="center">-</span>}</div></div>
                                                                            </div>
                                                                        )
                                                                    }


                                                                </div>

                                                                :

                                                                <div className="row P20">
                                                                    <div className="pl-4">{languagecontent['norecordsadded']}</div>
                                                                </div>

                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        : ""}

                                {
                                    isLab != 0 ?
                                        <div className="card Detail">
                                            <div className="card-body">
                                                <div>
                                                    <div className="row sectionTitle">
                                                        <div className="col-6 col-xs-9 col-md-6 marginAuto">
                                                            <h4 className="text-left"><span className="sectionTitleText">{isLab.ClientTitle}</span></h4> </div>
                                                        <div className="col-6 col-xs-3 col-md-6 marginAuto text-right"></div>
                                                    </div>


                                                    <div className="card Detail">
                                                        {
                                                            this.props.Lab.length > 0 ?

                                                                <div className="card-body">

                                                                    {
                                                                        this.props.Lab.map((lab: any, index: number) =>
                                                                            <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                <div className="col-12 col-lg-6 col-xs-6 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['testname']} </b></div> <div className="tbldesc">
                                                                                    {lab.labOrderDetails && lab.labOrderDetails.length > 0 ? lab.labOrderDetails.map((labOrderDetails: any) => (
                                                                                        <p><span>{labOrderDetails.labTest.name}</span></p>
                                                                                    )
                                                                                    ) : ""}
                                                                                    <p><span >{languagecontent['orderby']} </span><span>{lab.requestedByName} {languagecontent['orderon']} {dateFormat(lab.orderDate)} {Moment(lab.orderDate).format('LT')}</span></p>
                                                                                </div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['status']}</b></div> <div className="tbldesc">{lab.orderStatus != null && lab.orderStatus != "" ? lab.orderStatus : <span className="center">-</span>} </div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>{languagecontent['notechild']}</b></div> <div className="tbldesc">{lab.note != null && lab.note != "" ? lab.note : <span className="center">-</span>} </div></div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>

                                                                :
                                                                <div className="row P20">
                                                                    <div className="pl-4">{languagecontent['norecordsadded']}</div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                {
                                    isRadiology != 0 ?
                                        <div className="card Detail">
                                            <div className="card-body">
                                                <div>
                                                    <div className="row sectionTitle">
                                                        <div className="col-6 col-xs-9 col-md-6 marginAuto">
                                                            <h4 className="text-left"><span className="sectionTitleText">{isRadiology.ClientTitle}</span></h4> </div>
                                                        <div className="col-6 col-xs-3 col-md-6 marginAuto text-right"></div>
                                                    </div>


                                                    <div className="card Detail">
                                                        {
                                                            this.props.Radiology.length > 0 ?

                                                                <div className="card-body">

                                                                    {
                                                                        this.props.Radiology.map((radialogy: any, index: number) =>
                                                                            <div className={index > 0 ? "row detail-row" : "row"}>
                                                                                <div className="col-12 col-lg-6 col-xs-6 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>Test Name </b></div> <div className="tbldesc">
                                                                                    {radialogy.details && radialogy.details.length > 0 ? radialogy.details.map((Details: any) => (
                                                                                        <p><span>{Details.procedureCode.name}</span></p>
                                                                                    )

                                                                                    ) : ""}


                                                                                    {radialogy.prescribedByName != null && radialogy.prescribedByName != "" ? <p><span className="doc-speciality">{languagecontent['updatedby']} </span><span>{radialogy.prescribedByName} {languagecontent['updatedon	']} {dateFormat(radialogy.prescribedOn)} {Moment(radialogy.prescribedOn).format('LT')}</span></p> : ""}


                                                                                </div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>Status</b></div> <div className="tbldesc">{radialogy.orderStatus != null && radialogy.orderStatus != "" ? radialogy.orderStatus : <span className="center">-</span>} </div></div>
                                                                                <div className="col-12 col-lg-3 col-xs-3 col-md-12 mblerow"> <div className={index == 0 ? "tblhead" : "tblhead hideTitle"}><b>Note</b></div> <div className="tbldesc">{radialogy.note != null && radialogy.note != "" ? radialogy.note : <span className="center">-</span>} </div></div>

                                                                            </div>
                                                                        )
                                                                    }


                                                                </div>

                                                                :

                                                                <div className="row P20">
                                                                    <div className="pl-4">{languagecontent['norecordsadded']}</div>
                                                                </div>

                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                {/*{*/}
                                {/*    IsNote != 0 ?*/}
                                {/*        <div className="card Detail">*/}
                                {/*            <div className="card-body">*/}
                                {/*                <div>*/}
                                {/*                    <div className="row sectionTitle">*/}
                                {/*                        <div className="col-6 col-xs-9 col-md-6 marginAuto">*/}
                                {/*                            <h4 className="text-left"><span className="sectionTitleText">{IsNote.ClientTitle}</span></h4> </div>*/}
                                {/*                        <div className="col-6 col-xs-3 col-md-6 marginAuto text-right"></div>*/}
                                {/*                    </div>*/}


                                {/*                    <div className="card Detail">*/}
                                {/*                        {*/}
                                {/*                            this.props.notes.length > 0 ?*/}

                                {/*                                <div className="card-body">*/}

                                {/*                                    {*/}
                                {/*                                        this.props.notes.map((note: any, index: number) =>*/}
                                {/*                                            <div className={index > 0 ? "row detail-row" : "row"}>*/}
                                {/*                                                <div className="col-12 col-xs-6 col-md-12 mblerow">*/}
                                {/*                                                    <p dangerouslySetInnerHTML={{ __html: note.description.replace(/width: ?(\d+(\.\d+)?px)/g, ' width: 100%;"').replace(/ style="/g, ' style="white-space: normal;') }}></p>*/}
                                {/*                                                </div>*/}
                                {/*                                                <p className="mt-5" style={{ marginLeft: "20px" }}><small><span >{languagecontent['updatedby']} </span><span>{note.modified.fullName} {languagecontent['updatedon']} {dateTimeFormat(note.modifiedOn)} </span></small></p>*/}
                                {/*                                            </div>*/}
                                {/*                                        )*/}
                                {/*                                    }*/}
                                {/*                                </div>*/}

                                {/*                                :*/}
                                {/*                                <div className="row P20">*/}
                                {/*                                    <div className="pl-4">{languagecontent['norecordsadded']}</div>*/}
                                {/*                                </div>*/}
                                {/*                        }*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        : ""}*/}

                                <div className="card">
                                    <div className="mt-3 mr-3 row">
                                        <div className="mob-col1 col-md-7 col-lg-7"><h5 className="ml-4">{languagecontent['document']}</h5></div>
                                        {

                                            this.props.encounterDetail.statusId == 13 || this.props.encounterDetail.statusId == 8 ?
                                                "" : <div className="mob-col2 col-md-5 col-lg-5 text-right"><button type="button" className="btn btn-primary submit-btn" data-toggle="modal" data-target="#myModal"> {languagecontent['documentaddbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['documentaddbutton']}</button>
                                                </div>
                                        }

                                    </div>
                                    <DocumentList />
                                </div>
                                <DocumentUpload />
                                {
                                    IsVital != 0 ?
                                        <div className="card Detail">
                                            <div className="card-body">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-sm-12 marginAuto">
                                                            <h4 className="text-left"><span className="sectionTitleText">{IsVital.ClientTitle}</span></h4> </div>
                                                    </div>
                                                    <div className="card Detail">
                                                        <div className="card-body">
                                                            {otherfilter.length == 0 ? "" : <h5 className="text-left text-muted"><span className="sectionTitleText">Vitals </span></h5>
                                                            }
                                                            {
                                                                this.props.visitDetailLoading == true ? <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                    viewBox="30 0 500 50" height={50} width={500}>
                                                                    <rect x="18" y="0" rx="0" ry="0" width="141" height="38" />
                                                                    <rect x="182" y="0" rx="0" ry="0" width="141" height="38" />
                                                                    <rect x="343" y="0" rx="0" ry="0" width="141" height="38" />
                                                                </ContentLoader> :
                                                                    <div>
                                                                        <div className="table-responsive" style={{ flexWrap: "nowrap" }}>
                                                                            <table className="table table-hover table-center mb-0" style={{ maxWidth: "90%" }}>
                                                                                <tbody>
                                                                                    <td style={{ borderTop: "1px solid rgb(255 255 255)" }}>
                                                                                        <div className="row pb-0" style={{ flexWrap: "nowrap" }}>

                                                                                            {/*{otherfilter.length == 0 ? "" : <small style={{ fontSize: "10px" }}>{languagecontent['takenon'] + " " + dateTimeFormat(otherfilter[0].takenOn)}</small>}*/}

                                                                                            {otherfilter.map((patientvital: VisitDetail.PatientVital) =>
                                                                                                <div className="col-auto col-lg-auto col-xs-4 col-md-auto vitals"><div className="tbldesc"><b>{patientvital.vital.name}</b><br />{patientvital.value == "" ? "-" : patientvital.value} <small> {patientvital.vital.customizeValue}</small></div></div>
                                                                                            )}
                                                                                            {
                                                                                                bpsisfilter.length == 0 ? "" :
                                                                                                    <div className="col-auto col-lg-auto col-xs-4 col-md-auto vitals"><div className="tbldesc"><b>{(bpsisfilter[0].vital.name).toLowerCase() == "sys bp" ? "BP" : bpsisfilter[0].vital.name}</b><br />{bpsisfilter[0].value == "" ? "-" : bpsisfilter[0].value + "/" + bpdiafilter[0].value}  <small> {bpsisfilter[0].vital.customizeValue}</small></div></div>
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tbody>
                                                                            </table></div>
                                                                        <div className="col-sm-12 ml-2 pb-1">
                                                                            {otherfilter.length == 0 ? "" : <small style={{ fontSize: "10px" }}>{languagecontent['takenon'] + " " + dateTimeFormat(otherfilter[0].takenOn)}</small>}
                                                                        </div>
                                                                        <div>
                                                                            {otherfilter.length == 0 && bpsisfilter.length == 0 ? <div className="p-4">{languagecontent['norecordsadded']}</div> : ""}
                                                                        </div>
                                                                    </div>


                                                            }

                                                        </div>
                                                    </div>
                                                    {
                                                        ((hightfilter.length == 0) && (weightfilter.length == 0)) ? "" :
                                                            <div className="card Detail">
                                                                <div className="card-body">
                                                                    <h5 className="text-left text-muted"><span className="sectionTitleText">Body Measurements</span></h5>
                                                                    {
                                                                        this.props.visitDetailLoading == true ? <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                            viewBox="30 0 500 50" height={50} width={500}>
                                                                            <rect x="18" y="0" rx="0" ry="0" width="141" height="38" />
                                                                            <rect x="182" y="0" rx="0" ry="0" width="141" height="38" />
                                                                            <rect x="343" y="0" rx="0" ry="0" width="141" height="38" />
                                                                        </ContentLoader> :
                                                                            <div className="row pb-0">
                                                                                {hightfilter.map((patientvital: VisitDetail.PatientVital) =>
                                                                                    <div className="col-auto col-lg-auto col-xs-4 col-md-auto vitals"><div className="tbldesc"><b>{patientvital.vital.name}</b><br />{patientvital.value == "" ? "-" : patientvital.value} <small> {patientvital.vital.customizeValue}</small></div></div>
                                                                                )}
                                                                                {weightfilter.map((patientvital: VisitDetail.PatientVital) =>
                                                                                    <div className="col-auto col-lg-auto col-xs-4 col-md-auto vitals"><div className="tbldesc"><b>{patientvital.vital.name}</b><br />{patientvital.value == "" ? "-" : patientvital.value} <small> {patientvital.vital.customizeValue}</small></div></div>
                                                                                )}
                                                                                {
                                                                                    _resultbmi == false ? "" :
                                                                                        <div>
                                                                                            {

                                                                                                this.props.BMI.bMI == "" ? "" :
                                                                                                    <div className="col-auto col-lg-auto col-xs-4 col-md-auto vitals"><div className="tbldesc"><b>BMI</b><br /> <small> {this.props.BMI.bMI} {this.props.BMI.bMIValue}</small></div></div>

                                                                                            }
                                                                                        </div>
                                                                                }
                                                                                {hightfilter.length == 0 && weightfilter.length == 0 && hightfilter.length == 0 && weightfilter.length == 0 ? <div className="p-4">{languagecontent['norecordsadded']}</div> : ""}
                                                                                <div className="col-sm-12 ml-2">
                                                                                    {hightfilter.length == 0 ? "" : <small style={{ fontSize: "10px" }}>{languagecontent['takenon'] + " " + dateTimeFormat(hightfilter[0].takenOn)}</small>}
                                                                                </div>
                                                                            </div>

                                                                    }

                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div> : ""
                                }

                                {
                                    Object.values(customform).map((array: any) =>
                                        <div className="card Detail paymentDetail free">
                                            <div className="card-body">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-9 col-xs-9 col-md-6 ">
                                                            <h4 className="text-left"><span className="sectionTitleText">{array[0].formClientTitle}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="card Detail">
                                                        {
                                                            Object.entries(groupBy(array, "modifiedOn")).map((formdata: any) =>
                                                                <div className="p-4">


                                                                    {
                                                                        formdata.length == 0 ?
                                                                            <div className="row">
                                                                                <div className="pl-4">{languagecontent['norecordsadded']}</div>
                                                                            </div>
                                                                            :

                                                                            <div className="row">

                                                                                <div className="col-sm-9">
                                                                                    {
                                                                                        formdata[1].map((data: any) => (
                                                                                            <React.Fragment>
                                                                                                {data.value == null || data.value == "" ?
                                                                                                    <div className="row">
                                                                                                        {
                                                                                                            data.modifiedOn == null || data.modifiedOn == "" ? <div className="pl-4">{languagecontent['norecordsadded']}</div> : <div className="pl-4"></div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <React.Fragment>
                                                                                                        <strong>{data.clientTitle}</strong>
                                                                                                        {data.dataTypeId == 37 ?
                                                                                                            <p dangerouslySetInnerHTML={{ __html: data.value.replace(/~/g, '<br />') }}></p>
                                                                                                            : data.dataTypeId == 5 ? <p>{data.value.replaceAll('-', '/')}</p>
                                                                                                                : <p>{data.value.replaceAll('~', ', ').replace('|', ', ')}<br /></p>}

                                                                                                        {/*<p>{data.value.replaceAll('~', ', ').replace('|', ', ')}<br /></p>*/}
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            </React.Fragment>

                                                                                        ))}

                                                                                </div>
                                                                            </div>
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                        {array[0].modifiedUser != null && array[0].modifiedUser != "" ?
                                                            <p className="mb-3" style={{ marginLeft: "20px" }}><small>{languagecontent['updatedby']} {array[0].modifiedUser} {languagecontent['updatedon']} {dateTimeFormat(array[0].modifiedOn)}</small></p> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {this.props.paymentDetail.length == 0 ?
                                    <div className="card Detail paymentDetail free">
                                        <div className="card-body">
                                            <div>
                                                <div className="row sectionTitle">
                                                    <div className="col-9 col-xs-9 col-md-6 ">
                                                        <h4 className="text-left"><span className="sectionTitleText">{languagecontent['paymentinformation']}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="card card-table">
                                                    <div className="card-body">
                                                        <div className="row P20">
                                                            <div className="col-12 col-xs-8 col-lg-8">

                                                                <div className="row">
                                                                    <div className="col-6 col-lg-3 col-xs-3 col-md-6">{languagecontent['servicetype']}</div>
                                                                    <div className="col-6 col-lg-6 col-xs-3 col-md-6">{this.props.appointmentDetail ? this.props.appointmentDetail.appointmentType : this.props.encounterDetail ? this.props.encounterDetail.encounterTypeMode : ""}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-xs-4 col-lg-4">
                                                                <div className="row reverse">
                                                                    <div className="col-12 col-lg-12 col-xs-12 col-md-12 amtshow">
                                                                        <h3>{languagecontent['free']}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div>
                                        {
                                            this.props.paymentDetail.map((PaymentDetail: TransactionStore.PaymentDetail) =>
                                                <div>
                                                    {
                                                        PaymentDetail.Price != 'Free' ?
                                                            <div className="card Detail paymentDetail">
                                                                <div className="card-body">

                                                                    <div>
                                                                        <div className="row sectionTitle">
                                                                            <div className="col-9 col-xs-9 col-md-6 ">
                                                                                <h4 className="text-left"><span className="sectionTitleText">{languagecontent['paymentinformation']}</span></h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card card-table">
                                                                            <div className="card-body">
                                                                                <div className="row P20">
                                                                                    <div className="col-12 col-xs-8 col-lg-8">
                                                                                        <div className="row">
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{languagecontent['receiptno']}</div>
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6 profile-det-info"><h3>{PaymentDetail.ReceiptNo}</h3></div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{languagecontent['date']}</div>
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{dateFormat(PaymentDetail.PaidDate) + " " + Moment(PaymentDetail.PaidDate).format('LT')}</div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{languagecontent['paymentmode']}</div>
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{PaymentDetail.PaymentMethod}</div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{languagecontent['servicetype']}</div>
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6 profile-dot">{PaymentDetail.ServiceType}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-xs-4 col-lg-4">
                                                                                        <div className="row reverse">
                                                                                            <div className="col-12 col-lg-12 col-xs-12 col-md-12">
                                                                                                <div className=" marginAuto text-right">
                                                                                                    <span className={paymentstatusColor(PaymentDetail.PaymentStatus)}>{PaymentDetail.PaymentStatus}</span> <a onClick={() => this.onDownloadPaymentPrint(PaymentDetail.Id, discountnumber)} className='btn btn-sm bg-primary-light'><i className='fas fa-print'></i> {languagecontent['print']}</a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-lg-12 col-xs-12 col-md-12 " style={{textAlign: "right"}}>
                                                                                                <span className="">{languagecontent['paidamount']}</span>
                                                                                                <h3>{currency + getDiscountPrice(PaymentDetail.PaidAmount, 0)}</h3>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <div className="card Detail paymentDetail free">
                                                                <div className="card-body">
                                                                    <div>
                                                                        <div className="row sectionTitle">
                                                                            <div className="col-9 col-xs-9 col-md-6 ">
                                                                                <h4 className="text-left"><span className="sectionTitleText">{languagecontent['paymentinformation']}</span></h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card card-table">
                                                                            <div className="card-body">
                                                                                <div className="row P20">
                                                                                    <div className="col-12 col-xs-8 col-lg-8">

                                                                                        <div className="row">
                                                                                            <div className="col-6 col-lg-3 col-xs-3 col-md-6">{languagecontent['servicetype']}</div>
                                                                                            <div className="col-6 col-lg-6 col-xs-3 col-md-6">{this.props.appointmentDetail ? this.props.appointmentDetail.appointmentType : this.props.encounterDetail ? this.props.encounterDetail.encounterTypeMode : ""}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-xs-4 col-lg-4">
                                                                                        <div className="row reverse">
                                                                                            <div className="col-12 col-lg-12 col-xs-12 col-md-12 amtshow">
                                                                                                <h3>{languagecontent['free']}</h3>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            )

                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CancelAppointmentModel />
            </React.Fragment>
        );

    }
}




export default connect(
    (state: ApplicationState) => ({ ...state.dashboardNav, ...state.login, ...state.profile, ...state.visitDetail, ...state.printDetail, ...state.transactionDetail, ...state.siteDetail, ...state.documentlist, ...state.account, ...state.encounterModules, ...state.langResources, ...state.cancelAppointment }), // Selects which state properties are merged into the component's props
    ({ ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ProfileStore.actionCreators, ...VisitDetail.actionCreators, ...PrintDetail.actionCreators, ...TransactionStore.actionCreators, ...documentStore.actionCreators, ...Account.actionCreators, ...EncounterModuleStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...SiteDetailStore.actionCreators, ...CancelAppointmentStore.actionCreators }) // Selects which action creators are merged into the component's props
)(VisitDetailView as any);

function groupBy(data: any, key: any) {
    return data.reduce(function (acc: any, item: any) {
        (acc[item[key]] = acc[item[key]] || []).push(item);
        return acc;
    }, {});
};

function getBMICalc(vitalgroup: any, takenon: any) {
    //var vitalgroup = filter(data, 'TakenOn', takenon);
    //var Weight;
    //var Height;
    var bmivalue: any;
    if (vitalgroup == undefined) {
        return "-<br/><br/>";
    }
    else {
        vitalgroup.forEach(function (vital: any, key: any) {
            if (vital.vital.BMI != undefined) {
                if (vital.vital.BMI != "") {
                    bmivalue = vital.Vital.BMI;
                }
                else {
                    bmivalue = "-";
                }
            }
            else {
                bmivalue = "-";
            }
        })


        if (bmivalue != "-") {
            if (bmivalue < 16)
                return "<div class='highrisk-bmi'>" + bmivalue + "<br/>" + " (Underweight)</div>";
            else if (bmivalue >= 16 && bmivalue < 18.50)
                return "<div class='mediumrisk-bmi'>" + bmivalue + "<br/>" + " (Underweight)</div>";
            else if (bmivalue < 18.50)
                return bmivalue + "<br/>" + " (Underweight)";

            else if (bmivalue >= 18.50 && bmivalue <= 24.90)
                return bmivalue + "<br/>" + " (Normal)";

            else if (bmivalue >= 25.00 && bmivalue <= 29.90)
                return bmivalue + "<br/>" + " (Overweight)";

            else if (bmivalue >= 30.00)
                return bmivalue + "<br/>" + " (Obese)";
            else
                return "-<br/><br/>" + bmivalue;
        }
        else {
            return bmivalue;
        }
    }

}




